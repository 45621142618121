import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import { getVisitorById } from '../../../redux/visitor/visitorActions';
import VisitorHistory from './VisitorHistory';
import { format, parseISO } from 'date-fns/esm';
import toMaterialStyle from 'material-color-hash';
import { ROLE_SUPER_ADMIN } from '../../../redux/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  card: {
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardContent: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    backgroundColor: red[500],
  },
  labelBadge: {
    background: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
  },
  tabs: {
    flexGrow: 1,
  },
}));

const VisitorView = ({ match, history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const current = useSelector((state) => state.visitor.current);
  const currentUser = useSelector((state) => state.auth.current);

  useEffect(() => {
    dispatch(getVisitorById(match.params.id));
    // eslint-disable-next-line
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action) => {
    setAnchorEl(null);
    if (action === 'edit') {
      history.push(`/admin/main/visitors/edit/${match.params.id}`);
    }
  };

  const [visitorTab, setVisitorTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setVisitorTab(newValue);
  };

  return (
    <div className={classes.root}>
      {current && (
        <Grid container spacing={3}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  className={classes.avatar}
                  style={{
                    backgroundColor: toMaterialStyle(
                      `${current.firstName}
                    }`,
                    ).backgroundColor,
                  }}
                >
                  {current.firstName.charAt(0)}
                </Avatar>
              }
              action={
                <IconButton onClick={handleClick} aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title={`${current.firstName} ${current.lastName}`}
            />
            {currentUser && currentUser.roleName === ROLE_SUPER_ADMIN && (
              <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => handleClose('edit')}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    Edit
                  </Typography>
                </MenuItem>
              </Menu>
            )}
            <CardContent>
              <Grid container spacing={3}>
              
                {/* Email */}
                <Grid item xs={12} md={2}>
                  <Typography variant="body2">
                    <strong>Email</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="body2">{current.email}</Typography>
                </Grid>
                {/* Mobile Number */}
                <Grid item xs={12} md={2}>
                  <Typography variant="body2">
                    <strong>Mobile Number</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="body2">
                    {current.mobileNumber}
                  </Typography>
                </Grid>
                {/* Login Type */}
                <Grid item xs={12} md={2}>
                  <Typography variant="body2">
                    <strong>Login Type</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="body2">{current.authType}</Typography>
                </Grid>
                {/* Last Active */}
                <Grid item xs={12} md={2}>
                  <Typography variant="body2">
                    <strong>Last Active</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="body2"></Typography>
                  {format(
                    parseISO(current.lastActive, 'yyyy-MM-dd', new Date()),
                    'pp dd-MM-yyyy',
                  )}
                </Grid>
                {/* Active */}
                <Grid item xs={12} md={2}>
                  <Typography variant="body2">
                    <strong>Active</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="body2"></Typography>
                  {current.active ? 'YES' : 'NO'}
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card className={classes.card} style={{ marginTop: '1rem' }}>
            <Grid item xs={12}>
              <Paper>
                <Tabs
                  value={visitorTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  variant="fullWidth"
                >
                  <Tab label="History" />
                  {/* <Tab label="Notifications" /> */}
                  {/* <Tab label="Item Two" />
          <Tab label="Item Three" /> */}
                </Tabs>

                <div>
                  {visitorTab === 0 && (
                    <div>
                      <VisitorHistory id={current.id} />
                    </div>
                  )}
                </div>
              </Paper>
            </Grid>
          </Card>
        </Grid>
      )}
    </div>
  );
};

export default VisitorView;
