import firebase from 'firebase/app';
import 'firebase/analytics';

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyC0WQTtrulUn3zwwpWIu9aI3LR_uXLohx4',
  authDomain: 'soja-pass.firebaseapp.com',
  databaseURL: 'https://soja-pass.firebaseio.com',
  projectId: 'soja-pass',
  storageBucket: 'soja-pass.appspot.com',
  messagingSenderId: '106524970822',
  appId: '1:106524970822:web:2f4f2d3faeb62108199f47',
  measurementId: 'G-XLQ3E6HMEY',
};

export default firebase.initializeApp(firebaseConfig);
firebase.analytics();
