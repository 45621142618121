import {
  ADD_INVITE,
  GET_INVITES,
  DELETE_INVITE,
  UPDATE_INVITE,
  GET_INVITE,
} from './inviteTypes';

const initialState = {
  invites: null,
  current: null,
  error: null,
  loading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVITES:
      return {
        ...state,
        invites: action.payload,
      };

    case ADD_INVITE:
      return {
        ...state,
        current: null,
        invites: {
          ...state.invites,
          data:
            state.invites && state.invites.data.length > 0
              ? state.invites.data.concat(action.payload)
              : [action.payload],
        },
      };
    case DELETE_INVITE:
      return {
        ...state,
        invites: {
          ...state.invites,
          data:
            state.invites && state.invites.data.length > 0
              ? state.invites.data.filter(
                  (invite) => (invite.id = action.payload),
                )
              : [],
        },
      };
    case UPDATE_INVITE:
      return {
        ...state,
        current: null,
        invites: {
          ...state.invites,
          data:
            state.invites && state.invites.data.length > 0
              ? state.invites.data.map(
                  (invite) =>
                    (invite.id = action.payload.id ? action.payload : invite),
                )
              : [],
        },
      };
    case GET_INVITE:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};
