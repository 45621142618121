import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Admin from './Admin';
import AlertButton from '../components/AlertButton';
import { SW_UPDATE } from '../redux/service-worker/serviceWorkerTypes';
import Main from './Main';
const Layout = () => {
  const isServiceWorkerUpdated = useSelector(
    (state) => state.serviceWorker.serviceWorkerUpdated,
  );
  const serviceWorkerRegistration = useSelector(
    (state) => state.serviceWorker.serviceWorkerRegistration,
  );

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  return (
    <div>
      <Switch>
        <Route path="(^/admin)" component={Admin} />
        <Route path="/" component={Main} />

      </Switch>
      {isServiceWorkerUpdated && (
        <div className="App-alert">
          <AlertButton
            title="Notice"
            text="There is a new version available."
            buttonText="Update"
            type={SW_UPDATE}
            onClick={updateServiceWorker}
          />
        </div>
      )}
    </div>
  );
};

export default Layout;
