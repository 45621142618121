import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector, useDispatch } from 'react-redux';
import {
  addMessageGroup,
  addMessageGroupAdmin,
} from '../../redux/message-group/messageGroupActions';
import VisitorSelectList from './VisitorSelectList';
import { ROLE_SUPER_ADMIN } from '../../redux/role/roleTypes';
import { getOrganizations } from '../../redux/organization/organizationActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  unitTypeCard: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const AddMessageGroup = ({ history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);
  const organizations = useSelector((state) => state.organization.organizations);
  const currentUser = useSelector((state) => state.auth.current);

  const [form, setForm] = useState({
    name: '',
    description: '',
    visitorIds: [],
    organizationId:null
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Add Message Group') &&
      notification &&
      notification.type === 'success'
    ) {
      history.push('/admin/main/message-group');
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(form);
    if(currentUser && currentUser.roleName===ROLE_SUPER_ADMIN){
        dispatch(addMessageGroupAdmin(form));
    }else{
        dispatch(addMessageGroup(form));
    }
  };

  const [visitorPickerOpen, setVisitorPickerOpen] = useState(false);

  const handleVisitorPickerClose = () => {
    setVisitorPickerOpen(false);
  };
  const handleVisitorIds = (ids) => {
    setForm({ ...form, visitorIds: ids });
  };

  useEffect(() => {
     if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
       dispatch(getOrganizations());
     }
     // eslint-disable-next-line
  }, [currentUser]);



  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid container direction="row" spacing={2}>
            {currentUser && currentUser.roleName === ROLE_SUPER_ADMIN && (
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-organization-simple">
                    Select Organization
                  </InputLabel>
                  <Select
                    // inputRef={register}
                    name="organizationId"
                    inputProps={{
                      name: 'organizationId',
                      id: 'outlined-organization-simple',
                    }}
                    value={form.organizationId}
                    onChange={onChange}
                  >
                    {organizations &&
                      organizations.data.map((organization) => (
                        <MenuItem key={organization.id} value={organization.id}>
                          <em>{organization.name}</em>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                variant="outlined"
                required
                fullWidth
                onChange={onChange}
                id="name"
                label="Name of Group"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="description"
                variant="outlined"
                required
                fullWidth
                onChange={onChange}
                id="description"
                label="Description"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                style={{ marginTop: '2rem' }}
                variant="outlined"
                color="primary"
                onClick={() => setVisitorPickerOpen(true)}
              >
                Select Visitors{' '}
                {form.visitorIds.length !== 0 && `(${form.visitorIds.length})`}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              style={{ marginTop: '2rem' }}
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                form.visitorIds.length === 0 ||
                !form.name ||
                !form.description ||
                loading
              }
            >
              Add Message Group
            </Button>
          </Grid>
          <Grid item xs={12}>
            {loading && <LinearProgress />}
          </Grid>
        </Grid>
      </form>
      {/* Dialog */}
      <VisitorSelectList
        visitorPickerOpen={visitorPickerOpen}
        handleVisitorPickerClose={handleVisitorPickerClose}
        handleVisitorIds={handleVisitorIds}
      />
    </Paper>
  );
};

export default AddMessageGroup;
