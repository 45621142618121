import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { useSelector, useDispatch } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import { getMessageGroupById } from '../../../redux/message-group/messageGroupActions';
import MessageGroupRecipients from './MessageGroupRecipients';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  card: {
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardContent: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    backgroundColor: red[500],
  },
  labelBadge: {
    background: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
  },
  tabs: {
    flexGrow: 1,
  },
}));

const MessageGroupView = ({ match, history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const current = useSelector((state) => state.messageGroup.current);

  useEffect(() => {
    dispatch(getMessageGroupById(match.params.id));
    // eslint-disable-next-line
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action) => {
    setAnchorEl(null);
    if (action === 'edit') {
      history.push(`/admin/main/message-group/edit/${match.params.id}`);
    }
  };

  const [messageGroupTab, setMessageGroupTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setMessageGroupTab(newValue);
  };

  return (
    <div className={classes.root}>
      {current && (
        <Grid container spacing={3}>
          <Card className={classes.card}>
            <CardHeader
              action={
                <IconButton onClick={handleClick} aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title={current.name}
              subheader={current.description}
            />

            <Menu
              id="fade-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => handleClose('edit')}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                  Edit
                </Typography>
              </MenuItem>
              {/* <MenuItem onClick={() => handleClose('delete')}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                  Delete
                </Typography>
              </MenuItem>
           */}
            </Menu>
          </Card>

          <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <Paper>
              <Tabs
                value={messageGroupTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                className={classes.tabs}
                textColor="primary"
                centered
                variant="fullWidth"
              >
                <Tab label="Recipients" />
              </Tabs>

              <div className={classes.tabs}>
                {messageGroupTab === 0 && current && (
                  <div>
                    <MessageGroupRecipients
                      visitors={current.visitorDetails}
                      history={history}
                    />
                  </div>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default MessageGroupView;
