import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import toMaterialStyle from 'material-color-hash';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailIcon from '@material-ui/icons/Mail';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PersonIcon from '@material-ui/icons/Person';
import DomainIcon from '@material-ui/icons/Domain';
import SaveIcon from '@material-ui/icons/Save';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import {
  registerVisitor,
  updateVisitor,
} from '../redux/visitor/visitorActions';
import { AUTH_TYPE } from '../redux/visitor/visitorTypes';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  image: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: '0 auto',
  },
}));

const Profile = ({ history }) => {
  const dispatch = useDispatch();
  const visitor = useSelector((state) => state.visitor.auth);
  const loading = useSelector((state) => state.notification.loading);
  const organization = useSelector(
    (state) => state.visitor.authCurrentOrganization,
  );
  const notification = useSelector((state) => state.notification.notification);
  const invite = useSelector((state) => state.invite.current);

  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    authType: '',
    image: null,
  });
  const classes = useStyles();

  useEffect(() => {
    if (visitor && organization) {
      profile.id = visitor.id;
      profile.visitorId = visitor.visitorId;
      profile.firstName = visitor.firstName;
      profile.lastName = visitor.lastName;
      profile.image = visitor.image;
      profile.email = visitor.email && visitor.email;
      profile.mobileNumber = visitor.mobileNumber.substring(1);

      profile.authType = visitor.authType ?? AUTH_TYPE.GMAIL;
      profile.organizationId = organization.id;
      setProfile(profile);
    }

    // eslint-disable-next-line
  }, [visitor, organization]);

  const onChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
    // changeSettings(profile);
  };

  useEffect(() => {
    // updateCurrentUser(profile);
    // eslint-disable-next-line
  }, [profile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (invite) {
      profile.inviteCode = invite.value;
    }
    if (!profile.id) {
      dispatch(registerVisitor(profile));
    } else {
      dispatch(updateVisitor(profile));
    }
  };

  useEffect(() => {
    if (notification && notification.type === 'success') {
      // history.push('/checkin');
    }
    // eslint-disable-next-line
  }, [notification]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            padding: '1rem',
          }}
        >
          <div style={{ marginTop: 16 }}>
            {!profile.image ? (
              <Avatar
                className={classes.image}
                style={{
                  backgroundColor: toMaterialStyle(
                    `${profile.email && profile.email}${
                      profile.mobileNumber && profile.mobileNumber
                    }`,
                  ).backgroundColor,
                }}
                onClick={() => history.push('/checkin/profile/upload')}
              >
                {profile.firstName && profile.firstName.toString().charAt(0)}
                {!profile.firstName && profile.mobileNumber && '254'}
              </Avatar>
            ) : (
              <Avatar
                className={classes.image}
                src={profile.image}
                onClick={() => history.push('/checkin/profile/upload')}
              />
            )}
          </div>
          <div style={{ marginTop: 16 }}>
            <TextField
              id="firstName"
              name="firstName"
              value={profile.firstName}
              label="First Name"
              autoComplete="none"
              variant="outlined"
              onChange={onChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <TextField
              id="lastName"
              name="lastName"
              variant="outlined"
              label="Last Name"
              onChange={onChange}
              value={profile.lastName}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              name="email"
              value={profile.email}
              type="email"
              autoComplete="none"
              onChange={onChange}
              disabled={profile.authType === AUTH_TYPE.GMAIL}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <TextField
              id="mobile Number"
              variant="outlined"
              name="mobileNumber"
              value={profile.mobileNumber}
              onChange={onChange}
              disabled={profile.authType === AUTH_TYPE.PHONE}
              type="number"
              label="Mobile Number"
              autoComplete="none"
              placeholder="Enter Mobile Number"
              fullWidth
              InputProps={{
                max: 9,
                endAdornment: (
                  <InputAdornment position="end">
                    <PhoneAndroidIcon />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">+254 </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <TextField
              id="organization"
              variant="outlined"
              name="organization"
              value={organization && organization.name ? organization.name : ''}
              onChange={onChange}
              disabled
              label="Organization"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DomainIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {invite && (
            <div style={{ marginTop: 16 }}>
              <TextField
                id="invite"
                variant="outlined"
                name="Invite"
                value={invite.value}
                disabled
                autoComplete="none"
                label="Invite Code"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFormatIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
         
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            className={classes.button}
            disabled={
              loading ||
              !profile.firstName ||
              !profile.lastName ||
              !profile.email ||
              !profile.mobileNumber 
            }
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          {loading && <LinearProgress />}
        </form>
      </Grid>
    </Grid>
  );
};

export default Profile;
