import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION
} from './notificationTypes';

// Set Notification
export const setNotification = data => async dispatch => {
  dispatch({ type: SET_NOTIFICATION, payload: data });

  setTimeout(() => dispatch({ type: CLEAR_NOTIFICATION }), 5000);
};
// Clear Notification
export const clearNotification = () => async dispatch => {
  setTimeout(() => dispatch({ type: CLEAR_NOTIFICATION }), 5000);
};

// Set Loading
export const setLoading = () => async dispatch => {
  dispatch({ type: SET_LOADING });
};

// Clear Loading
export const clearLoading = () => async dispatch => {
  dispatch({ type: CLEAR_LOADING });
};
