import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateMessageGroup,
  getMessageGroupById,
  updateMessageGroupAdmin,
} from '../../redux/message-group/messageGroupActions';
import VisitorSelectList from './VisitorSelectList';
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Collapse from '@material-ui/core/Collapse';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import {  getOrganizations } from '../../redux/organization/organizationActions';
import { ROLE_SUPER_ADMIN } from '../../redux/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  unitTypeCard: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const EditMessageGroup = ({ history, match }) => {
  const classes = useStyles();

  const [form, setForm] = useState({
    name: '',
    description: '',
    visitorIds: [],
    organizationId:''
  });

  const dispatch = useDispatch();

  const notification = useSelector((state) => state.notification.notification);
  const page = useSelector((state) => state.page.page);
  const current = useSelector((state) => state.messageGroup.current);
  const organizations = useSelector((state) => state.organization.organizations);
  const currentUser = useSelector((state) => state.auth.current);

  useEffect(() => {
    if (!current) {
      dispatch(getMessageGroupById(match.params.id));
    }
    // eslint-disable-next-line
  }, []);
  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Edit Message Group') &&
      notification &&
      notification.type === 'success'
    ) {
      history.push('/admin/main/message-group');
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(form);
    if(currentUser && currentUser.roleName===ROLE_SUPER_ADMIN){
    dispatch(updateMessageGroupAdmin(form, match.params.id));
    }else{
    dispatch(updateMessageGroup(form, match.params.id));
    }
  };

  const [visitorPickerOpen, setVisitorPickerOpen] = useState(false);

  const handleVisitorPickerClose = () => {
    setVisitorPickerOpen(false);
  };
  const handleVisitorIds = (ids) => {
    setForm({ ...form, visitorIds: ids });
  };


  useEffect(() => {
    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(getOrganizations());
    }
    // eslint-disable-next-line
  }, [currentUser]);
  useEffect(() => {
    if (current) {
      form.name = current.name;
      form.description = current.description;
      form.visitorDetails = current.visitorDetails;

      if (current.visitorDetails) {
        const visitorIds = [];

        for (const visitor of current.visitorDetails) {
          visitorIds.push(visitor.id);
        }
        form.visitorIds = visitorIds;
      }
      if(current.organizationId){
        form.organizationId=current.organizationId
      }
      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);

  if (!current) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid container direction="row" spacing={2}>
              {true&& (
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-organization-simple">
                      Select Organization
                    </InputLabel>
                    <Select
                      // inputRef={register}
                      name="organizationId"
                      inputProps={{
                        name: 'organizationId',
                        id: 'outlined-organization-simple',
                      }}
                      value={form.organizationId}
                      onChange={onChange}
                    >
                      {organizations &&
                        organizations.data.map((organization) => (
                          <MenuItem
                            key={organization.id}
                            value={organization.id}
                          >
                            <em>{organization.name}</em>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  variant="outlined"
                  required
                  fullWidth
                  onChange={onChange}
                  id="name"
                  label="Name of Group"
                  autoFocus
                  value={form.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="description"
                  variant="outlined"
                  required
                  fullWidth
                  onChange={onChange}
                  id="description"
                  label="Description"
                  autoFocus
                  value={form.description}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  style={{ marginTop: '2rem' }}
                  variant="outlined"
                  color="primary"
                  onClick={() => setVisitorPickerOpen(true)}
                >
                  Select Visitors{' '}
                  {form.visitorIds.length !== 0 &&
                    `(${form.visitorIds.length})`}
                </Button>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              style={{ marginTop: '2rem' }}
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                form.visitorIds.length === 0 || !form.name || !form.description
              }
            >
              Update Message Group
            </Button>
          </Grid>
        </form>
        {/* Dialog */}

        <VisitorSelectList
          visitorIds={form.visitorIds}
          visitorPickerOpen={visitorPickerOpen}
          handleVisitorPickerClose={handleVisitorPickerClose}
          handleVisitorIds={handleVisitorIds}
        />
      </Paper>
    );
  }
};

export default EditMessageGroup;
