import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import { GET_SETTING, SETTING_ERROR } from './settingTypes';

const url = process.env.REACT_APP_API_URL + 'settings';

let notification;

// Get Setting

export const getSetting = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(`${url}`);

    dispatch({ type: GET_SETTING, payload: res.data });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: SETTING_ERROR,
      payload: 'Failed to Get Setting',
    });
  }
};

// Update Setting

export const updateSetting = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    // dispatch({ type: UPDATE_UNIT, payload: res.data });
    // Set Register Success Notification

    dispatch({ type: GET_SETTING, payload: res.data });

    const notification = {
      message: `Setting Updated`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: SETTING_ERROR,
      payload: 'Failed to Update Setting',
    });
  }
};
