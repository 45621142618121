import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Notification from './components/layout/Notification';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Layout from './containers/Layout';

function App() {
  const darkMode = localStorage.getItem('darkMode');
  const organization = useSelector((state) => state.auth.organization);
  const visitorOrganization = useSelector(
    (state) => state.visitor.authCurrentOrganization,
  );

  const [primaryColor, setPrimaryColor] = useState(`#D32F2F`);
  const [secondaryColor, setSecondaryColor] = useState(`#000`);

  useEffect(() => {
    if (localStorage.getItem('primaryColor')) {
      setPrimaryColor(localStorage.getItem('primaryColor'));
    }
    if (localStorage.getItem('secondaryColor')) {
      setSecondaryColor(localStorage.getItem('secondaryColor'));
    }
  }, []);

  useEffect(() => {
    if (organization || visitorOrganization) {
      let currentOrg = organization ? organization : visitorOrganization;
      if (currentOrg.primaryColor) {
        localStorage.setItem('primaryColor', currentOrg.primaryColor);
        setPrimaryColor(currentOrg.primaryColor);
      }
      if (currentOrg.secondaryColor) {
        localStorage.setItem('secondaryColor', currentOrg.secondaryColor);
        setSecondaryColor(currentOrg.secondaryColor);
      }
    }
    // eslint-disable-next-line
  }, [organization, visitorOrganization]);

  const theme = createMuiTheme({
    overrides: {
      // 'MuiTabs-indicator': {
      //   root: {
      //     backgroundColor: '#FFF',
      //   },
      // },
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      type: darkMode === 'true' ? 'dark' : 'light',
    },
  });

  theme.typography.h2 = {
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '6rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '2rem',
    },
  };
  theme.typography.h4 = {
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1.2rem',
    },
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={1}>
          <Notification
            style={{
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
          <Router>
            <Route path="/" component={Layout} />
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
