import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import Navbar from '../../components/layout/Navbar';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Helmet } from 'react-helmet';
import CheckInHome from './CheckInHome';
import Profile from '../Profile';
import { Offline } from 'react-detect-offline';
import Typography from '@material-ui/core/Typography';
import PortableWifiOffIcon from '@material-ui/icons/PortableWifiOff';
import CheckInHistory from './CheckInHistory';
import UploadImage from '../UploadImage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    // marginTop: '4rem',
  },
  mainContent: {
    display: 'flex',
  },
  notification: {
    position: 'fixed',
    top: 0,
    left: 0,
  },
  offline: {
    padding: theme.spacing(1),
    background: '#000',
    color: '#FFF',
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 2,
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    display: 'block',
    zIndex: theme.zIndex.drawer + 200,
  },
}));

const CheckIn = ({ location, history }) => {
  const classes = useStyles();

  // const dispatch = useDispatch();
  const page = useSelector((state) => state.page.page);
  const organization = useSelector((state) => state.organization.current);

  // test
  // const [params, setParams] = useState(null);
  // useEffect(() => {
  //   // TODO Simplify this.
  //   const params = qs.parse(location.search.replace('?', ''));
  //   if (params && params.org != null) {
  //     // If not logged in go to sign in with passId
  //     if (!visitor) {
  //       history.push(`/signin/${params.org}`);
  //       return;
  //     }
  //     setParams(params);
  //     // Prompt Add Organization
  //     // dispatch(getOrganizationByPassId(params.org));
  //   }
  //   // eslint-disable-next-line
  // }, [visitor, organization]);

  return (
    <div className={classes.root}>
      {page && organization && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${organization.name} | ${page}`}</title>
          <link rel="icon" href={`${organization.image}`} sizes="16x16" />
        </Helmet>
      )}
      <Fragment>
        <Offline>
          <Typography className={classes.offline}>
            <PortableWifiOffIcon />
            No Internet Connection
          </Typography>
        </Offline>
        <Navbar />
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.content}>
              <Switch>
                <Route exact path="/checkin" component={CheckInHome} />
                <Route exact path="/checkin/profile" component={Profile} />
                <Route
                  exact
                  path="/checkin/history"
                  component={CheckInHistory}
                />
                <Route
                  exact
                  path="/checkin/profile/upload"
                  component={UploadImage}
                />
              </Switch>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    </div>
  );
};

export default CheckIn;
