import {
  GET_USER,
  GET_USERS,
  DELETE_USER,
  UPDATE_USER,
  ADD_USER,
} from './userTypes';

const initialState = {
  users: null,
  current: null,
  loading: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case ADD_USER:
      return {
        ...state,
        users: {
          ...state.users,
          data:
            state.users && state.users.data.length > 0
              ? state.users.data.concat(action.payload)
              : [action.payload],
          count: state.users && state.users.count + 1,
        },
      };
    case GET_USER:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        current: null,
        users: {
          ...state.users,
          data:
            state.users && state.users.data.length > 0
              ? state.users.data.map(
                  (user) =>
                    (user.id = action.payload.id ? action.payload : user),
                )
              : [],
        },
      };
    case DELETE_USER:
      return {
        ...state,
        loading: false,
        users: {
          ...state.users,
          data:
            state.users &&
            state.users.data.filter((user) => user.id !== action.payload),
        },
      };
    default:
      return state;
  }
};
