import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

const Notification = () => {
  const classes = useStyles();

  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (notification != null && notification.message && notification.type) {
      enqueueSnackbar(notification.message, {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        preventDuplicate: true,
        variant: notification.type,
        autoHideDuration: 1000,
      });
    }
    // eslint-disable-next-line
  }, [notification, loading]);

  // console.log(loading);
  if (loading) {
    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Notification;
