import axios from 'axios';
import { CLEAR_LOADING, SET_LOADING } from '../notification/notificationTypes';
import { GET_HISTORIES, HISTORY_ERROR } from './historyTypes';

const url = process.env.REACT_APP_API_URL + 'history';

export const getHistories = (params) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}`, { params });

    const histories = res.data;

    dispatch({ type: GET_HISTORIES, payload: histories });
    dispatch({ type: CLEAR_LOADING });
    // setTimeout(() => {
    //   dispatch({ type: CLEAR_NOTIFICATION });
    // }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: HISTORY_ERROR,
      payload: 'Failed to Get History ',
    });
  }
};

export const getVisitorHistories = (params) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/visitor`, { params });

    const histories = res.data;

    dispatch({ type: GET_HISTORIES, payload: histories });
    dispatch({ type: CLEAR_LOADING });
    // setTimeout(() => {
    //   dispatch({ type: CLEAR_NOTIFICATION });
    // }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: HISTORY_ERROR,
      payload: 'Failed to Get History ',
    });
  }
};
