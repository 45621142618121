import {
  GET_MESSAGE_GROUPS,
  ADD_MESSAGE_GROUP,
  REMOVE_MESSAGE_GROUP,
  UPDATE_MESSAGE_GROUP,
  GET_MESSAGE_GROUP
} from './messageGroupTypes';

const initialState = {
  messageGroups: null,
  current: null,
  error: null,
  loading: false
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGE_GROUPS:
      return {
        ...state,
        messageGroups: action.payload
      };

    case ADD_MESSAGE_GROUP:
     return {
       ...state,
       messageGroups: {
         ...state.messageGroups,
         data:
           state.messageGroups && state.messageGroups.count > 0
             ? state.messageGroups.data.concat(action.payload)
             : [action.payload],
       },
     };
    case REMOVE_MESSAGE_GROUP:
       return {
         ...state,
         messageGroups: {
           ...state.messageGroups,
           data:
             state.messageGroups &&
             state.messageGroups.data &&
             state.messageGroups.data.filter(
               (messageGroup) => messageGroup.id !== action.payload,
             ),
           count: state.messageGroups.count-1,
         },
         current: null,
       };
    case UPDATE_MESSAGE_GROUP:
      return {
        ...state,
        current:null,
        messageGroups: {
          ...state.messageGroups,
          data:
            state.messageGroups &&
            state.messageGroups.count > 0 ?
            state.messageGroups.data.map(
              (messageGroup) =>
                (messageGroup.id = action.payload.id
                  ? action.payload
                  : messageGroup),
            ):
            state.messageGroups.data
        },
      };
    case GET_MESSAGE_GROUP:
      return {
        ...state,
        current: action.payload
      };

    default:
      return state;
  }
};
