export const REGISTER_VISITOR = 'REGISTER_VISITOR';
export const REGISTER_GMAIL = 'REGISTER_GMAIL';
export const DISPLAY_VISITOR_QR = 'DISPLAY_VISITOR_QR';
export const UPDATE_VISITOR = 'UPDATE_VISITOR';
export const GET_VISITORS = 'GET_VISITORS';
export const GET_VISITOR = 'GET_VISITOR';
export const ADD_VISITOR = 'ADD_VISITOR';
export const ADD_VISITORS = 'ADD_VISITORS';
export const DELETE_VISITOR = 'DELETE_VISITOR';
export const CLEAR_VISITOR = 'CLEAR_VISITOR';
export const SET_VISITOR = 'SET_VISITOR';
export const VISITOR_ERROR = 'VISITOR_ERROR';
export const GET_ENCRYPTED_QR = 'GET_ENCRYPTED_QR';

export const GET_VISITOR_ORGANIZATIONS = 'GET_VISITOR_ORGANIZATIONS';
export const SET_VISITOR_ORGANIZATION = 'SET_VISITOR_ORGANIZATION';

export const AUTH_TYPE = {
  GMAIL: 'GMAIL',
  PHONE: 'PHONE',
};
