import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Sidebar from './Sidebar';
import { CssBaseline, lighten } from '@material-ui/core';
import { logOut } from '../../redux/auth/authActions';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import { ROLE_ADMIN } from '../../redux/role/roleTypes';
import toMaterialStyle from 'material-color-hash';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.between('xs', 'md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  account: {
    display: 'flex',
  },
  nav: {
    backgroundColor: lighten(theme.palette.primary.main, 0.05),
    borderRadius: '2%',
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.1),
    },
  },
  appBar: {
    // marginLeft: drawerWidth,
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(100% - ${drawerWidth}px)`
    // }
  },
}));

const AdminNavbar = ({ history, location }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const dispatch = useDispatch();
  const current = useSelector((state) => state.auth.current);
  const organization = useSelector((state) => state.auth.organization);
  const page = useSelector((state) => state.page.page);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {}, [page]);

  const logout = (e) => {
    dispatch(logOut());
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {current && current.roleName === ROLE_ADMIN && (
        <MenuItem
          component={Link}
          to="/admin/main/settings"
          onClick={handleMenuClose}
        >
          Settings
        </MenuItem>
      )}
      <MenuItem onClick={logout}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        component={Link}
        to="/main/admin/settings"
        onClick={handleProfileMenuOpen}
      >
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Settings</p>
      </MenuItem>

      <MenuItem onClick={logout}>
        <IconButton
          aria-label="logout"
          aria-controls="primary-search-logout-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Log out</p>
      </MenuItem>
    </Menu>
  );

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const [darkMode, setDarkMode] = useState(false);

  //  Beginning of Members check dark mode
  useEffect(() => {
    const dkMode = localStorage.getItem('darkMode');

    if (dkMode === 'true') {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  const handleDarkMode = () => {
    console.log(darkMode);
    localStorage.setItem('darkMode', !darkMode);
    // Reload Page to reflect changes
    window.location.reload();
  };

  return (
    <div className={classes.root}>
      <div className={classes.grow}>
        <CssBaseline />

        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {page ? page : organization ? organization.name : 'Soja Pass'}
            </Typography>
            <div className={classes.search} hidden>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {current && (
                <ListItem
                  alignItems="center"
                  button
                  dense
                  className={classes.nav}
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      style={{
                        backgroundColor: toMaterialStyle(
                          `${current.firstName} ${current.lastName}`,
                        ).backgroundColor,
                      }}
                    >
                      {current.firstName.toString().charAt(0)}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={current.firstName}
                    style={{ marginRight: '0.5em' }}
                  />
                  <ListItemIcon style={{ marginRight: '-2em' }}>
                    <KeyboardArrowDownIcon
                      htmlColor={theme.palette.common.white}
                    />
                  </ListItemIcon>
                </ListItem>
              )}
              <IconButton
                onClick={handleDarkMode}
                aria-label="Dark Mode"
                color="inherit"
              >
                {darkMode ? <Brightness5Icon /> : <Brightness2Icon />}
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              {current && (
                <ListItem
                  alignItems="center"
                  button
                  dense
                  className={classes.nav}
                  color="inherit"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      style={{
                        backgroundColor: toMaterialStyle(
                          `${current.firstName} ${current.lastName}`,
                        ).backgroundColor,
                      }}
                    >
                      {current.firstName.toString().charAt(0)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={current.firstName}
                    style={{ marginRight: '0.5em' }}
                  />
                  <ListItemIcon style={{ marginRight: '-2em' }}>
                    <KeyboardArrowDownIcon
                      htmlColor={theme.palette.common.white}
                    />
                  </ListItemIcon>
                </ListItem>
              )}
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </div>

      <Sidebar
        selected={page}
        mobileOpen={mobileOpen}
        closeDrawer={closeDrawer}
        location={location}
      />
    </div>
  );
};

export default AdminNavbar;
