import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import TuneIcon from '@material-ui/icons/Tune';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { GrIntegration } from 'react-icons/gr';
import BusinessIcon from '@material-ui/icons/Business';
import { ROLE_ORG_ADMIN, ROLE_SUPER_ADMIN } from '../../redux/role/roleTypes';
const organizationOptions = [
  {
    name: 'Organization',
    icon: <BusinessIcon />,
    link: '/admin/main/settings/organization',
  },
   {
    name: 'Options',
    icon: <TuneIcon />,
    link: '/admin/main/settings/options',
  },
  {
    name: 'Integrations',
    icon: <GrIntegration size={24} />,
    link: '/admin/main/settings/integrations',
  },
];
const accountOptions = [
  {
    name: 'Profile',
    icon: <AccountCircleIcon />,
    link: '/admin/main/settings/account',
  },
];

const SettingsTab = ({ location }) => {
  // const classes = useStyles();
  const current = useSelector((state) => state.auth.current);

  return (
    <Grid container justify="center" direction="column" spacing={2}>
      {current &&
        (current.roleName === ROLE_ORG_ADMIN ||
          current.roleName === ROLE_SUPER_ADMIN) && (
          <Grid item xs={12}>
            <Paper>
              <List
                component="nav"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Organization
                  </ListSubheader>
                }
              >
                {organizationOptions.map((option, index) => (
                  <ListItem
                    button
                    key={index}
                    component={Link}
                    to={option.link}
                    selected={
                      location && location.pathname.endsWith(option.link)
                    }
                    // onClick={(event) => handleListItemClick(event, index)}
                  >
                    <ListItemIcon>{option.icon}</ListItemIcon>
                    <ListItemText primary={option.name} />
                  </ListItem>
                ))}
              </List>
              <Divider />
            </Paper>
          </Grid>
        )}
      <Grid item xs={12}>
        <Paper>
          <List
            component="nav"
            subheader={<ListSubheader component="div">Account</ListSubheader>}
          >
            {accountOptions.map((option, index) => (
              <ListItem
                button
                key={index}
                component={Link}
                to={option.link}
                selected={location && location.pathname.includes(option.link)}
                // onClick={(event) => handleListItemClick(event, index)}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText primary={option.name} />
              </ListItem>
            ))}
          </List>
          <Divider />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SettingsTab;
