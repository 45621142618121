import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Loader from '../pages/Loader';
import SignIn from '../pages/SignIn';
import CheckIn from '../pages/checkin/CheckIn';
import * as firebaseConfig from 'firebase';
import setAuthToken from '../utils/setAuthToken';
import { getCurrentVisitor } from '../redux/visitor/visitorActions';
import { setCurrentPage } from '../redux/pages/pagesActions';
import { Helmet } from 'react-helmet';
import * as qs from 'query-string';
import { getVisitorInviteByID } from '../redux/invite/inviteActions';
import { getOrganizationByPassId } from '../redux/organization/organizationActions';
// import { getOrganizationByPassId } from '../redux/organization/organizationActions';

const Main = ({ location, history }) => {
  const dispatch = useDispatch();

  const current = useSelector((state) => state.visitor.auth);
  const page = useSelector((state) => state.page.page);
  const organization = useSelector(
    (state) => state.visitor.authCurrentOrganization,
  );

  const invite = useSelector((state) => state.invite.current);

  // useEffect(() => {
  //   dispatch(getOrganizationByPassId());
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    dispatch(
      getOrganizationByPassId(organization ? organization.passId : undefined),
    );
    // eslint-disable-next-line
  }, []);

  // Check Invite
  useEffect(() => {
    if (invite && current) {
      if (
        invite.contact !== current.email &&
        invite.contact !== current.mobileNumber
      ) {
        dispatch(getOrganizationByPassId(undefined));
      } else {
        dispatch(getOrganizationByPassId(invite.passId));
      }
    }
    // eslint-disable-next-line
  }, [invite, current]);

  useEffect(() => {
    // Listen for Route Changes

    const { pathname } = location;

    dispatch(setCurrentPage(pathname));

    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (current) {
      console.log('Push to Check In');
      history.push('/checkin');
    }
    // eslint-disable-next-line
  }, [current]);

  useEffect(() => {
    if (!localStorage.profile && !window.location.pathname.includes('signin')) {
      history.push('/signin');
    }
    if (!navigator.onLine && localStorage.getItem('qr') != null) {
      history.push('/checkin');
    }

    firebaseConfig.default.auth().onAuthStateChanged(async (userAuth) => {
      const params = qs.parse(location.search.replace('?', ''));

      let inviteKey;

      if (window.location.pathname.includes('signin')) {
        if (params && params.k) {
          // console.log(params);
          inviteKey = params.k;
          if (!invite) dispatch(getVisitorInviteByID(inviteKey));
        }
      }

      // console.log(userAuth);
      if (userAuth) {
        const token = await userAuth.getIdToken(true);

        // console.log('Token' + token);
        setAuthToken(token);

        dispatch(getCurrentVisitor());
        if (inviteKey) {
         if (!invite) dispatch(getVisitorInviteByID(inviteKey));
        }

        // dispatch(getVisitorOrganizations({ passId: passId }));

        // console.log(userAuth.getIdToken());
      } else {
        if (window.location.pathname.includes('signin')) {
          // dispatch(getOrganizationByPassId(passId));
          return;
        }

        // console.log('Push to Signin');
        history.push('/signin');
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        {page && organization && (
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`${organization.name} | ${page}`}</title>
            {/* <link rel="icon" href={`${organization.image}`} sizes="16x16" /> */}
          </Helmet>
        )}
        <Route exact path="/" component={Loader} />
        <Route path="/signin" component={SignIn} />
        <Route path="/signin/:id" component={SignIn} />
        <Route path="/checkin" component={CheckIn} />
      </div>
    </>
  );
};

export default Main;
