import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addInvite, addInviteAdmin } from '../../redux/invite/inviteActions';
import { ROLE_SUPER_ADMIN } from '../../redux/role/roleTypes';
import { getOrganizations } from '../../redux/organization/organizationActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  unitTypeCard: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const AddInviteDialog = ({ addInviteOpen, handleAddInviteClose }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const loading = useSelector((state) => state.notification.loading);
  const currentUser = useSelector((state) => state.auth.current);
  const organizations = useSelector(
    (state) => state.organization.organizations,
  );

  const [form, setForm] = useState({
    mode: 'sms',
    contact: '',
    send: false,
    organizationId: null,
  });

  const handleSubmit = () => {
    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(addInviteAdmin(form));
    } else {
      dispatch(addInvite(form));
    }
    handleAddInviteClose();
  };
  const onChange = (e) => {
    if (e.target.name === 'mode') {
      form.contact = '';
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(getOrganizations());
    }
    // eslint-disable-next-line
  }, [currentUser]);
  return (
    <Dialog
      open={addInviteOpen}
      onClose={handleAddInviteClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create Invite</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-mode-simple">
                Select Mode
              </InputLabel>
              <Select
                fullWidth
                value={form.mode}
                onChange={onChange}
                name="mode"
                inputProps={{
                  name: 'mode',
                  id: 'outlined-mode-simple',
                }}
              >
                <MenuItem value="sms">
                  <em>SMS</em>
                </MenuItem>
                <MenuItem value="email">
                  <em>Email</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              id="contact"
              name="contact"
              label="Contact"
              type={form.mode === 'email' ? 'email' : 'number'}
              onChange={onChange}
              placeholder={
                form.mode === 'email'
                  ? 'Enter Email Address'
                  : 'Enter Phone Number ( 07XX XXX XXX )'
              }
              variant="outlined"
              fullWidth
            />
          </Grid>
          {currentUser && currentUser.roleName === ROLE_SUPER_ADMIN && (
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-organization-simple">
                  Select Organization
                </InputLabel>
                <Select
                  // inputRef={register}
                  name="organizationId"
                  inputProps={{
                    name: 'organizationId',
                    id: 'outlined-organization-simple',
                  }}
                  value={form.organizationId}
                  onChange={onChange}
                >
                  {organizations &&
                    organizations.data.map((organization) => (
                      <MenuItem key={organization.id} value={organization.id}>
                        <em>{organization.name}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              label={`Send Invite Message via ${form.mode}`}
              value={form.send ? 'Yes' : 'No'}
              control={
                <Switch
                  color="primary"
                  value={form.send}
                  checked={form.send}
                  onChange={(e) => setForm({ ...form, send: e.target.checked })}
                />
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={form.contact.length === 0 || loading}
          onClick={handleSubmit}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInviteDialog;
