import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import { DropzoneDialog } from 'material-ui-dropzone';
import 'rc-color-picker/assets/index.css';
import {
  updateCurrentOrganization,
  updateOrganizationImage,
} from '../../redux/organization/organizationActions';
import { BlockPicker } from 'react-color';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  userCard: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  color: {
    width: '100%',
    height: '14px',
    borderRadius: '2px',
  },
  swatchPrimary: {
    padding: '1rem',
    background: theme.palette.primary,
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  swatchSecondary: {
    padding: '1rem',
    background: theme.palette.secondary,
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const SettingsOrganizationProfile = ({ history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.notification.loading);
  const organization = useSelector((state) => state.auth.organization);

  const theme = useTheme();

  const [form, setForm] = useState({
    name: '',
    address: '',
    phoneNo: '',
    email: '',
    image: '',
    active: true,
    primaryColor: theme.palette.primary,
    secondaryColor: theme.palette.secondary,
  });

  useEffect(() => {
    if (organization) {
      setForm(organization);
    }
    // eslint-disable-next-line
  }, [organization]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //   dispatch(getCurrentOrganization());
  //   // eslint-disable-next-line
  // }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(updateCurrentOrganization(form));
  };

  // Image
  const [imagePickerOpen, setImagePickerOpen] = useState(false);
  const [userImage, setUserImage] = useState('');
  const [file, setFile] = useState(null);

  const handleSave = (files) => {
    // Set Image
    const file = files[0];
    setFile(file);
    setUserImage(URL.createObjectURL(file));

    const formData = new FormData();
    formData.append('file', file);
    formData.append('organizationId', organization.id);

    dispatch(updateOrganizationImage(formData));

    handleClose();
  };
  const handleClose = () => {
    setImagePickerOpen(false);
  };
  if (!organization) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Typography color="textSecondary" gutterBottom>
          Profile
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={onChange}
                    id="name"
                    label="Name"
                    value={form.name}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="email"
                    variant="outlined"
                    fullWidth
                    type="email"
                    onChange={onChange}
                    id="email"
                    label="Enter email"
                    value={form.email}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="phoneNo"
                    variant="outlined"
                    fullWidth
                    type="text"
                    onChange={onChange}
                    id="phoneNumber"
                    label="Phone Number"
                    value={form.phoneNo}
                    autoFocus
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
              <img
                style={{ maxHeight: '10rem' }}
                onClick={() => setImagePickerOpen(true)}
                alt="Organization"
                src={
                  form.image
                    ? form.image
                    : userImage
                    ? userImage
                    : 'https://place-hold.it/300x200/F8F8F8?text=Add Organization Image'
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="address"
                variant="outlined"
                fullWidth
                type="text"
                onChange={onChange}
                id="address"
                label="Enter Address"
                value={form.address}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                <strong>Primary Color</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                <strong>Secondary Color</strong>
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <BlockPicker
                color={form.primaryColor}
                colors={[
                  '#FF6900',
                  '#FCB900',
                  '#7BDCB5',
                  '#00D084',
                  '#8ED1FC',
                  '#0693E3',
                  '#ABB8C3',
                  '#EB144C',
                  '#F78DA7',
                  '#9900EF',
                ]}
                onChange={(color, event) => {
                  setForm({ ...form, primaryColor: color.hex });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BlockPicker
                color={form.secondaryColor}
                colors={[
                  '#FF6900',
                  '#FCB900',
                  '#7BDCB5',
                  '#00D084',
                  '#8ED1FC',
                  '#0693E3',
                  '#ABB8C3',
                  '#EB144C',
                  '#F78DA7',
                  '#9900EF',
                ]}
                onChange={(color, event) => {
                  setForm({ ...form, secondaryColor: color.hex });
                }}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            style={{ marginTop: '2rem' }}
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!form.name || (!file && !form.image) || loading}
          >
            Update Organization
          </Button>
          <Grid item xs={12}>
            {loading && <LinearProgress variant="query" color="secondary" />}
          </Grid>
        </form>

        <DropzoneDialog
          open={imagePickerOpen}
          onSave={handleSave}
          acceptedFiles={['image/*']}
          showPreviews={true}
          dropzoneText="Drag and drop an Image file here"
          fileLimit={1} // Select only one image
          showPreviewsInDropzone={true}
          showAlerts={true}
          maxFileSize={5000000}
          onClose={handleClose}
        />
      </Paper>
    );
  }
};

export default SettingsOrganizationProfile;
