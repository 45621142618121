import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ShareIcon from '@material-ui/icons/Share';
import { Link } from 'react-router-dom';
import CustomSearchRender from '../../utils/CustomSearchRender';
import { format, parseISO } from 'date-fns/esm';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { getVisitors } from '../../redux/visitor/visitorActions';
import {
  ROLE_ADMIN,
  ROLE_ORG_ADMIN,
  ROLE_SUPER_ADMIN,
} from '../../redux/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  labelBadge: {
    background: theme.palette.primary.light,
    padding: theme.spacing(0.3),
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
  },
}));

const VisitorList = ({ history }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const currentUser = useSelector((state) => state.auth.current);
  const visitors = useSelector((state) => state.visitor.visitors);
  const notification = useSelector((state) => state.notification.notification);
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
    createdDateFrom: null,
    createdDateTo: null,
  });

  const handleFromDateChange = (date) =>
    setFilter({
      ...filter,
      createdDateFrom: date,
    });
  const handleToDateChange = (date) =>
    setFilter({ ...filter, createdDateTo: date });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        console.log(filter.sortOrder);
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getVisitors(filter));
    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (visitors) {
      var finalData =
        visitors.data &&
        visitors.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [visitors]);

  useEffect(() => {
    if (notification && notification.type === 'success') {
      dispatch(getVisitors(filter));
    }
    // eslint-disable-next-line
  }, [notification]);

  const options = {
    filterType: 'dropdown',
    serverSide: true,
    filter: true,
    rowsPerPage: filter.limit,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    count: visitors && visitors.count,
    rowHover: false,
    responsive: 'vertical',
    downloadOptions: { filename: 'visitors.csv', useDisplayedRowsOnly: false },
    onTableChange: (action, tableState) => {
      // TODO : See On Table Change
      // console.log(tableState.data[0]);
    },
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },

    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onFilterChange: (changedColumn, filterList, type) => {
      columns.map((column, idx) => {
        if (column.name === changedColumn) {
          const value = filterList[idx][0];

          setFilter({ ...filter, [changedColumn]: value });
          return true;
        }
        return 0;
      });
    },
    onColumnSortChange: (column, direction) => {
      // console.log(column);
      // console.log(direction);
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'ascending' ? 'asc' : 'desc',
      });
    },
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: {
        filter: false,
        display: true,
        sortOrder: getSortOrder('firstName'),
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: {
        filter: false,
        filterType: 'textField',
        sortOrder: getSortOrder('lastName'),
      },
    },
    {
      name: 'mobileNumber',
      label: 'Mobile No',
      options: {
        filter: false,
        sortOrder: getSortOrder('mobileNumber'),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body1">{value ?? 'None'}</Typography>;
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sortOrder: getSortOrder('email'),
      },
    },
    {
      name: 'authType',
      label: 'Login Type',
      options: {
        filter: false,
        sortOrder: getSortOrder('mobileNumber'),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'idValidated',
      label: 'ID Validated',
      options: {
        filter: false,
        sortOrder: getSortOrder('mobileNumber'),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <CheckIcon /> : <CloseIcon />;
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        display: true,
        sort: true,
        filterType: 'custom',
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1">
              {format(parseISO(value, 'yyyy-MM-dd', new Date()), 'do MMM yyyy')}
            </Typography>
          );
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            if (filters[0] && filters[1]) {
              return date < filters[0] || date > filters[1];
            } else if (filters[0]) {
              return date < filters[0];
            } else if (filters[1]) {
              return date > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Created Date</FormLabel>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="From"
                      inputVariant="outlined"
                      value={filter.createdDateFrom || new Date()}
                      onChange={(date) => {
                        console.log(filterList);
                        handleFromDateChange(date);
                        filterList[index][0] = date.toString();
                        onChange(filterList[index], index, column);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="To"
                      inputVariant="outlined"
                      value={filter.createdDateTo || new Date()}
                      onChange={(date) => {
                        filterList[index][1] = date.toString();
                        handleToDateChange(date);
                        onChange(filterList[index], index, column);
                      }}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </div>
          ),
        },
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'lastActive',
      label: 'Last Active',
      options: {
        display: true,
        sort: true,
        filterType: 'custom',
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1">
              {format(parseISO(value, 'yyyy-MM-dd', new Date()), 'do MMM yyyy')}
            </Typography>
          );
        },
      },
    },
    {
      name: 'view',
      label: 'Actions',
      options: {
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              component={Link}
              to={`/admin/main/visitors/view/${tableMeta.rowData[0]}`}
              label={value}
              variant="outlined"
              type="submit"
              color="secondary"
            >
              View
            </Button>
          );
        },
      },
    },
  ];

  return (
    <div>
      <Paper className={classes.root}>
        <div>
          {currentUser &&
            (currentUser.roleName === ROLE_SUPER_ADMIN ||
              currentUser.roleName === ROLE_ORG_ADMIN ||
              currentUser.roleName === ROLE_ADMIN) && (
              <Button
                variant="contained"
                size="large"
                color="primary"
                component={Link}
                to="/admin/main/visitors/add"
                className={classes.button}
                startIcon={<AddBoxIcon />}
              >
                Add Visitor
              </Button>
            )}

          <Button
            variant="contained"
            size="large"
            color="secondary"
            component={Link}
            to="/admin/main/invites"
            className={classes.button}
            startIcon={<ShareIcon />}
          >
            Invites
          </Button>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {data && (
              <MUIDataTable
                title={'Visitors List'}
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default VisitorList;
