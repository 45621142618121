import setAuthToken from '../../utils/setAuthToken';
import {
  CLEAR_VISITOR,
  GET_ENCRYPTED_QR,
  REGISTER_GMAIL,
  SET_VISITOR,
  GET_VISITORS,
  GET_VISITOR,
  UPDATE_VISITOR,
  DELETE_VISITOR,
  ADD_VISITOR,
  ADD_VISITORS,
  GET_VISITOR_ORGANIZATIONS,
  SET_VISITOR_ORGANIZATION,
} from './visitorTypes';
import * as firebaseConfig from 'firebase';

const initialState = {
  current: null,
  error: null,
  loading: false,
  qr: null,
  visitors: null,
  auth: null,
  authOrganizations: null,
  authCurrentOrganization: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_GMAIL:
      return {
        ...state,
        auth: action.payload,
        loading: false,
      };
    case SET_VISITOR:
      let visitor = action.payload;

      // if Visitor already fetched
      if (state.current && state.auth.id && !visitor.id) {
        visitor = state.current;
      }
      localStorage.setItem('profile', JSON.stringify(visitor));

      return {
        ...state,
        auth: visitor,
        loading: false,
      };
    case GET_ENCRYPTED_QR:
      localStorage.setItem('qr', action.payload);
      return {
        ...state,
        qr: action.payload,
      };
    case CLEAR_VISITOR:
      setAuthToken(null);
      localStorage.clear();
      firebaseConfig.auth().signOut();
      return {
        ...state,
        current: null,
        qr: null,
      };
    case GET_VISITORS:
      return {
        ...state,
        visitors: action.payload,
      };
    case GET_VISITOR:
      return {
        ...state,
        current: action.payload,
      };
    case UPDATE_VISITOR:
      return {
        ...state,
        current: null,
        visitors: {
          ...state.visitors,
          data:
            state.visitors && state.visitors.data.length > 0
              ? state.visitors.data.map(
                  (visitor) =>
                    (visitor.id = action.payload.id ? action.payload : visitor),
                )
              : [],
        },
      };
    case DELETE_VISITOR:
      return {
        ...state,
        visitors: {
          ...state.visitors,
          data:
            state.visitors && state.visitors.data.length > 0
              ? state.visitors.data.filter(
                  (visitor) => (visitor.id = action.payload),
                )
              : [],
        },
      };
    case ADD_VISITOR:
      return {
        ...state,
        visitors: {
          ...state.visitors,
          data:
            state.visitors && state.visitors.data.length > 0
              ? state.visitors.data.concat(action.payload)
              : [action.payload],
          count:
            state.visitors && state.visitors.count
              ? state.visitors.count + 1
              : 0,
        },
      };
    case ADD_VISITORS:
      return {
        ...state,
        visitors: {
          ...state.visitors,
          data:
            state.visitors && state.visitors.data.length > 0
              ? state.visitors.data.concat(action.payload.data)
              : action.payload.data,
          count:
            state.visitors && state.visitors.count
              ? state.visitors.count + action.payload.count
              : action.payload.count,
        },
      };
    case GET_VISITOR_ORGANIZATIONS:
      const firstOrg = action.payload.data[0];
      // let currentOrg;

      // if (
      //   state.authCurrentOrganization &&
      //   state.authCurrentOrganization.id.includes('000')
      // ) {
      //   currentOrg = state.authCurrentOrganization;
      // }
      // if (
      //   firstOrg &&
      //   state.authCurrentOrganization &&
      //   state.authCurrentOrganization.id.includes('000') &&
      //   !firstOrg.id.includes('000')
      // ) {
      //   currentOrg = firstOrg;
      // }
      // console.log(firstOrg);
      // console.log(currentOrg);
      // console.log(
      //   firstOrg &&
      //     state.authCurrentOrganization &&
      //     state.authCurrentOrganization.id.includes('000') &&
      //     !firstOrg.id.includes('000'),
      // );

      return {
        ...state,
        authOrganizations: action.payload,
        authCurrentOrganization: firstOrg && !firstOrg.id.includes("000")?firstOrg:state.authCurrentOrganization,
      };
    case SET_VISITOR_ORGANIZATION:
      return {
        ...state,
        authCurrentOrganization: action.payload,
      };
    default:
      return state;
  }
};
