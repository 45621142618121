import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import jwt from 'jsonwebtoken';
import { useSelector } from 'react-redux';
import {
  ROLE_ADMIN,
  ROLE_MANAGEMENT,
  ROLE_ORG_ADMIN,
} from '../redux/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 2),
    padding: theme.spacing(2),
    marginTop: '4rem',
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [url, setUrl] = useState(null);
  const darkMode = localStorage.getItem('darkMode');
  const organization = useSelector((state) => state.auth.organization);
  const current = useSelector((state) => state.auth.current);

  useEffect(() => {
    if (organization && current) {
      const dkMode = darkMode === 'true' ? '#theme=night&' : '';

      // TODO: Make Logic for Dashboard Change
      let dashboard;

      if (
        current.roleName === ROLE_ADMIN ||
        current.roleName === ROLE_ORG_ADMIN
      ) {
        dashboard = 2;
      } else if (current.roleName === ROLE_MANAGEMENT) {
        dashboard = 2;
      } else {
        dashboard = 1;
      }

      const payload = {
        resource: { dashboard },
        params: {},
        exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
      };
      if (dashboard !== 1) {
        payload.params['organizationid'] = organization.id;
      }
      const token = jwt.sign(
        payload,
        process.env.REACT_APP_METABASE_SECRET_KEY,
      );
      // console.log(process.env.REACT_APP_METABASE_SECRET_KEY);
      // console.log(process.env.REACT_APP_METABASE_URL);
      //
      const iframeUrl =
        process.env.REACT_APP_METABASE_URL +
        '/embed/dashboard/' +
        token +
        `${dkMode}#bordered=true&titled=true`;

      setUrl(iframeUrl);
    }
    // eslint-disable-next-line
  }, [organization, current]);

  // const url = process.e

  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <iframe
            title="Dashboard"
            src={url}
            frameBorder="0"
            // width="900"
            // height="600"
            allowtransparency="true"
            style={{
              display: 'block' /* iframes are inline by default */,
              background: '#fff',
              border: 'none' /* Reset default border */,
              height: '100vh' /* Viewport-relative units */,
              width: '100%',
            }}
          ></iframe>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Dashboard;
