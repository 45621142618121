import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import AlertTitle from '@material-ui/lab/AlertTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getIntegration,
  updateIntegration,
} from '../../redux/integration/integrationActions';
import { SMS_GATEWAY } from '../../redux/integration/integrationTypes';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const SettingsIntegrations = ({ match, location }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const integration = useSelector((state) => state.integration.current);
  const loading = useSelector((state) => state.notification.loading);

  useEffect(() => {
    // TODO Simplify this.
    dispatch(getIntegration());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (integration) {
      if (integration.atkAuth) setAtk(integration.atkAuth);
      if (integration.mailAuth) setMail(integration.mailAuth);
      if (integration.tililAuth) setTilil(integration.tililAuth);
      if (integration.smsGateway) setSmsGateway(integration.smsGateway);
    }
    // eslint-disable-next-line
  }, [integration]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [smsGateway, setSmsGateway] = useState(SMS_GATEWAY.AFRICAS_TALKING);
  const [atk, setAtk] = useState({ apiKey: '', username: '', from: '' });
  const [tilil, setTilil] = useState({ shortcode: '', apiKey: '' });
  const [mail, setMail] = useState({
    host: '',
    port: '',
    fromEmail: '',
    authUsername: '',
    authPassword: '',
    secure: false,
  });

  if (!integration) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div>
        {/* AfricaTalking */}
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              SMS Carrier Settings
            </Typography>
            <Typography className={classes.secondaryHeading}>
              Current {smsGateway.split('_').join('')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'block' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-sms-gateway-simple">
                    Select SMS Gateway
                  </InputLabel>
                  <Select
                    fullWidth
                    value={smsGateway}
                    onChange={(e) => setSmsGateway(e.target.value)}
                    name="smsGateway"
                    inputProps={{
                      name: 'smsGateway',
                      id: 'outlined-smsGateway-simple',
                    }}
                  >
                    {Object.entries(SMS_GATEWAY).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        <em>{value.split('_').join(' ')}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {smsGateway === SMS_GATEWAY.AFRICAS_TALKING && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Alert severity="info">
                    <AlertTitle>Notice</AlertTitle>
                    Setup Your account in{' '}
                    <Typography
                      component="a"
                      variant="body2"
                      target="_blank"
                      href="https://africastalking.com"
                    >
                      AfricasTalking
                    </Typography>
                    . Create an app and get the <strong>API KEY</strong> and{' '}
                    <strong>Username</strong>
                  </Alert>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    value={atk.username}
                    fullWidth
                    onChange={(e) =>
                      setAtk({ ...atk, username: e.target.value })
                    }
                    label="Username"
                    name="username"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    value={atk.apiKey}
                    fullWidth
                    label="API Key"
                    name="apiKey"
                    autoFocus
                    onChange={(e) => setAtk({ ...atk, apiKey: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    value={atk.from}
                    fullWidth
                    label="From"
                    name="from"
                    autoFocus
                    onChange={(e) => setAtk({ ...atk, from: e.target.value })}
                  />
                </Grid>
              </Grid>
            )}
            {smsGateway === SMS_GATEWAY.TILIL && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Alert severity="info">
                    <AlertTitle>Notice</AlertTitle>
                    Setup Your account in{' '}
                    <Typography
                      component="a"
                      variant="body2"
                      target="_blank"
                      href="https://bulk.tililtechnologies.co.ke/docs/home"
                    >
                      Tilil Technologies
                    </Typography>
                    . Create an app and get the <strong>API KEY</strong> and{' '}
                    <strong>shortcode (optional)</strong>
                  </Alert>
                </Grid>

                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    value={tilil.apiKey}
                    fullWidth
                    onChange={(e) =>
                      setTilil({ ...tilil, apiKey: e.target.value })
                    }
                    label="API Key"
                    name="apiKey"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    value={tilil.shortcode}
                    fullWidth
                    label="Shortcode"
                    name="shortcode"
                    autoFocus
                    onChange={(e) =>
                      setTilil({ ...tilil, shortcode: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
            )}

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={() =>
                    dispatch(
                      updateIntegration({
                        atkAuthDto: atk,
                        tililAuthDto: tilil,
                        smsGateway,
                      }),
                    )
                  }
                  disabled={loading}
                  endIcon={<SaveIcon />}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading}>Mail</Typography>
            <Typography className={classes.secondaryHeading}>
              Setup your Mail Settings
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  value={mail.host}
                  fullWidth
                  onChange={(e) => setMail({ ...mail, host: e.target.value })}
                  label="Host"
                  name="host"
                  autoFocus
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  value={mail.port}
                  fullWidth
                  type="number"
                  onChange={(e) => setMail({ ...mail, port: e.target.value })}
                  label="Port"
                  name="port"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  value={mail.authUsername}
                  fullWidth
                  onChange={(e) =>
                    setMail({ ...mail, authUsername: e.target.value })
                  }
                  label="Username"
                  name="username"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  value={mail.authPassword}
                  fullWidth
                  onChange={(e) =>
                    setMail({ ...mail, authPassword: e.target.value })
                  }
                  label="Password"
                  name="password"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  value={mail.fromEmail}
                  fullWidth
                  onChange={(e) =>
                    setMail({ ...mail, fromEmail: e.target.value })
                  }
                  label="From Email"
                  name="fromEmail"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label={'Secure'}
                  value={mail.secure ? 'Yes' : 'No'}
                  control={
                    <Switch
                      color="primary"
                      value={mail.secure ? 'Yes' : 'No'}
                      checked={mail.secure}
                      onChange={(e) =>
                        setMail({ ...mail, secure: e.target.checked })
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() =>
                    dispatch(updateIntegration({ mailAuthDto: mail }))
                  }
                  disabled={loading}
                  endIcon={<SaveIcon />}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
};

export default SettingsIntegrations;
