import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { Route, Switch } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import RefreshIcon from '@material-ui/icons/Refresh';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { getCurrentUser } from '../../redux/auth/authActions';
import SettingsOrganizationProfile from './SettingsOrganizationProfile';
import SettingsTab from './SettingsTab';
import SettingsProfile from './SettingsProfile';
import SettingsIntegrations from './SettingsIntegrations';
import SettingsBilling from './SettingsBilling';
import SettingsOptions from './SettingsOptions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(10, 2),
  },
  card: {
    padding: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  contentColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  labelBadge: {
    background: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
  },
}));
const SettingsHome = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const current = useSelector((state) => state.auth.current);

  useEffect(() => {
    dispatch(getCurrentUser());
    // eslint-disable-next-line
  }, []);

  // const [open, setOpen] = useState(false);

  if (!current) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <SettingsTab {...props} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Switch>
              <Route
                exact
                path="/admin/main/settings/organization"
                component={SettingsOrganizationProfile}
              />

              <Route
                exact
                path="/admin/main/settings/account"
                component={SettingsProfile}
              />
              <Route
                exact
                path="/admin/main/settings/integrations"
                component={SettingsIntegrations}
              />
              <Route
                exact
                path="/admin/main/settings/billing"
                component={SettingsBilling}
              />
               <Route
                exact
                path="/admin/main/settings/options"
                component={SettingsOptions}
              />
            </Switch>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default SettingsHome;
