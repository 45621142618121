import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Webcam from 'react-webcam';
import { updateVisitorImage } from '../redux/visitor/visitorActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
    height: '100vh',
  },
}));

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);
  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var dw = new DataView(ab);
  for (var i = 0; i < byteString.length; i++) {
    dw.setUint8(i, byteString.charCodeAt(i));
  }
  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
}
const videoConstraints = {
  width: 300,
  height: 300,
  facingMode: 'user',
};

const UploadImage = ({ history }) => {
  const dispatch = useDispatch();
  const visitor = useSelector((state) => state.visitor.auth);
  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);

  const classes = useStyles();

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log(imageSrc);
    const formData = new FormData();
    // toFile(imgSrc).then((file) => {
    const blob = dataURItoBlob(imageSrc);
    const file = new File(
      [blob],
      `${visitor.firstName}-${visitor.id}-profile.jpeg`,
    );
    console.log(file);

    formData.append('file', file);

    dispatch(updateVisitorImage(formData));

    // eslint-disable-next-line
  }, [webcamRef]);

  useEffect(() => {
    if (notification && notification.type === 'success') {
      history.push('/checkin/profile');
    }
    // eslint-disable-next-line
  }, [notification]);

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        xs={12}
        style={{
          width: '100%',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <Webcam
          audio={false}
          capture={capture}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          height={300}
          width={300}
          videoConstraints={videoConstraints}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          onClick={capture}
          disabled={loading}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
export default UploadImage;
