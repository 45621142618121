import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessIcon from '@material-ui/icons/Business';
import { useSelector } from 'react-redux';
import {
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
  ROLE_ORG_ADMIN,
} from '../../redux/role/roleTypes';
import { FaCopy } from 'react-icons/fa';
import RateReviewIcon from '@material-ui/icons/RateReview';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: theme.mixins.toolbar,
}));

const Sidebar = ({ selected, mobileOpen, closeDrawer, location }) => {
  const classes = useStyles();

  // const notification = useSelector((state) => state.notification.notification);
  const current = useSelector((state) => state.auth.current);

  const handleDrawerToggle = () => {
    closeDrawer();
  };

  const drawer = (
    <React.Fragment>
      <div className={classes.toolbar} />
      <List>
        <ListItem
          component={Link}
          to="/admin/main"
          selected={selected === 'Dashboard'}
          button
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        {current && current.roleName === ROLE_SUPER_ADMIN && (
          <ListItem
            component={Link}
            to="/admin/main/organizations"
            selected={selected === 'Organizations'}
            button
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Organizations" />
          </ListItem>
        )}
        {current &&
          (current.roleName === ROLE_ORG_ADMIN ||
            current.roleName === ROLE_SUPER_ADMIN) && (
            <ListItem
              component={Link}
              to="/admin/main/users"
              selected={selected === 'Users'}
              button
            >
              <ListItemIcon>
                <SupervisedUserCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          )}

        <ListItem
          component={Link}
          to="/admin/main/visitors"
          selected={selected === 'Visitors'}
          button
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Visitors" />
        </ListItem>

        {current &&
          (current.roleName === ROLE_ADMIN ||
            current.roleName === ROLE_SUPER_ADMIN ||
            current.roleName === ROLE_ORG_ADMIN) && (
            <>
              <ListItem
                component={Link}
                to="/admin/main/messaging"
                selected={selected === 'Messaging'}
                button
              >
                <ListItemIcon>
                  <RateReviewIcon />
                </ListItemIcon>
                <ListItemText primary="Messaging" />
              </ListItem>

              <ListItem
                component={Link}
                to="/admin/main/message-template"
                selected={selected === 'Message Templates'}
                button
              >
                <ListItemIcon>
                  <FaCopy size={24} />
                </ListItemIcon>
                <ListItemText primary="Message Templates" />
              </ListItem>
            </>
          )}

        <ListItem
          component={Link}
          to="/admin/main/settings/account"
          selected={location && location.pathname.includes('settings')}
          button
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>
    </React.Fragment>
  );
  return (
    <div>
      <Hidden smUp implementation="css">
        <Drawer
          className={classes.drawer}
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor={'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default Sidebar;
