import {
  GET_MESSAGE,
  ADD_MESSAGE,
  REMOVE_MESSAGE,
  UPDATE_MESSAGE,
  GET_MESSAGES,
} from './messageTypes';

const initialState = {
  messages: null,
  current: null,
  error: null,
  loading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };

    case ADD_MESSAGE:
      return {
        ...state,
        messages: state.messages.concat(action.payload),
      };
    case REMOVE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter(
          (messageGroup) => messageGroup.id !== action.payload,
        ),
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        messages: state.messages.map(
          (messageGroup) =>
            (messageGroup.id = action.payload.id
              ? action.payload
              : messageGroup),
        ),
      };
    case GET_MESSAGE:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};
