import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  MESSAGE_GROUP_ERROR,
  GET_MESSAGE_GROUPS,
  GET_MESSAGE_GROUP,
  REMOVE_MESSAGE_GROUP,
  ADD_MESSAGE_GROUP,
  UPDATE_MESSAGE_GROUP,
} from './messageGroupTypes';

const url = process.env.REACT_APP_API_URL + 'message-group';

let notification;

// Create Message Group

export const addMessageGroup = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({type:ADD_MESSAGE_GROUP,payload:res.data});

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const messageGroup = res.data;
    notification = {
      message: `Message Group Created ${messageGroup.name} successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_GROUP_ERROR,
      payload: 'Failed to Create Message Group',
    });
  }
};

export const addMessageGroupAdmin = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}/admin`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

     dispatch({ type: ADD_MESSAGE_GROUP, payload: res.data });

    const messageGroup = res.data;
    notification = {
      message: `Message Group Created ${messageGroup.name} successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_GROUP_ERROR,
      payload: 'Failed to Create Message Group',
    });
  }
};

// Update Message Group
export const updateMessageGroup = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: UPDATE_MESSAGE_GROUP, payload: res.data });
    // Set Register Success Notification

    const messageGroup = res.data;
    const notification = {
      message: `Updated Message Group ${messageGroup.name} successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_GROUP_ERROR,
      payload: 'Failed to Update Message Group',
    });
  }
};

export const updateMessageGroupAdmin = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/admin/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: UPDATE_MESSAGE_GROUP, payload: res.data });
    // Set Register Success Notification

    const messageGroup = res.data;
    const notification = {
      message: `Updated Message Group ${messageGroup.name} successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_GROUP_ERROR,
      payload: 'Failed to Update Message Group',
    });
  }
};

// Get Message Groups
export const getMessageGroups = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const messageGroups = res.data;
  dispatch({ type: GET_MESSAGE_GROUPS, payload: messageGroups });

  dispatch({ type: CLEAR_LOADING });
};

// Get Message Group By ID
export const getMessageGroupById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const messageGroup = res.data;
    dispatch({ type: GET_MESSAGE_GROUP, payload: messageGroup });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_GROUP_ERROR,
      payload: 'Failed to Get Message Group ',
    });
  }
};

// Delete Message Group
export const deleteMessageGroup = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);

    dispatch({ type: REMOVE_MESSAGE_GROUP, payload: id });
    
    const notification = {
      message: `Deleted Message Group`,
      type: 'info',
    };

    dispatch({ type: REMOVE_MESSAGE_GROUP, payload: id });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_GROUP_ERROR,
      payload: 'Failed to Delete Organization',
    });
  }
};
