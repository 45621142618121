import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  fade,
  makeStyles,
  lighten,
  useTheme,
  darken,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import RefreshIcon from '@material-ui/icons/Refresh';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { CssBaseline } from '@material-ui/core';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import toMaterialStyle from 'material-color-hash';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { logOut } from '../../redux/visitor/visitorActions';
import SelectOrganizaton from './SelectOrganization';
import { getVisitorOrganizations } from '../../redux/visitor/visitorActions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.between('xs', 'md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  account: {
    display: 'flex',
  },
  nav: {
    backgroundColor: lighten(theme.palette.primary.main, 0.05),
    borderRadius: '2%',
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.1),
    },
  },
  navOrg: {
    backgroundColor: darken(theme.palette.primary.main, 0.08),
    borderRadius: '2%',
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.1),
    },
  },
  appBar: {
    // marginLeft: drawerWidth,
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(100% - ${drawerWidth}px)`
    // }
  },
}));

const Navbar = ({ history }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [selectOrgOpen, setSelectOrgOpen] = useState(false);

  const dispatch = useDispatch();
  const theme = useTheme();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const page = useSelector((state) => state.page.page);
  const organization = useSelector(
    (state) => state.visitor.authCurrentOrganization,
  );
  const profile = useSelector((state) => state.visitor.auth);
  const organizations = useSelector((state) => state.visitor.authOrganizations);

  useEffect(() => {
    if (profile) {
      dispatch(getVisitorOrganizations());
    }
    // eslint-disable-next-line
  }, [profile]);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleSelectOrgClose = () => {
    setSelectOrgOpen(false);
  };

  useEffect(() => {}, [page]);

  const logout = (e) => {
    handleMobileMenuClose();
    dispatch(logOut());
  };

  const [darkMode, setDarkMode] = useState(false);

  //  Beginning of navbar check dark mode
  useEffect(() => {
    const dkMode = localStorage.getItem('darkMode');

    if (dkMode === 'true') {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  const handleDarkMode = () => {
    handleMobileMenuClose();
    localStorage.setItem('darkMode', !darkMode);
    // Reload Page to reflect changes
    window.location.reload();
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        component={Link}
        to="/checkin/profile"
        onClick={handleMenuClose}
      >
        Profile
      </MenuItem>
      <MenuItem
        component={Link}
        to="/checkin/history"
        onClick={handleMenuClose}
      >
        History
      </MenuItem>
      <MenuItem onClick={() => logout()}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        component={Link}
        to="/checkin/profile"
        onClick={handleMobileMenuClose}
      >
        <p>Profile</p>
      </MenuItem>
      <MenuItem
        component={Link}
        to="/checkin/history"
        onClick={handleMobileMenuClose}
      >
        <p>History</p>
      </MenuItem>
      <MenuItem onClick={handleDarkMode}>
        <p>Dark Mode</p>
      </MenuItem>
      <MenuItem onClick={() => logout()}>
        <p>Log out</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <div className={classes.grow}>
        <CssBaseline />

        <AppBar className={classes.appBar} position="static">
          <Toolbar>
            {page && (
              <IconButton component={Link} to={`/checkin`} color="inherit">
                <ArrowBackIcon style={{ color: '#FFF' }} />
              </IconButton>
            )}

            {organization && organizations && organizations.data.length <= 1 && (
              <Typography variant="h6" noWrap>
                {page ? page : organization && organization.name}
              </Typography>
            )}

            {!page && organizations && organizations.data.length > 1 && (
              <List component="nav">
                <ListItem
                  alignItems="center"
                  button
                  dense
                  className={classes.navOrg}
                  aria-label="org select open"
                  aria-haspopup="true"
                  onClick={() => setSelectOrgOpen(true)}
                  color="inherit"
                  style={{ fontSize: '0.7em' }}
                >
                  <ListItemText
                    primary={organization.name}
                    style={{
                      marginRight: '0.5em',
                      fontSize: '0.7em !important',
                    }}
                  />
                </ListItem>
              </List>
            )}

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {profile && (
                <ListItem
                  alignItems="center"
                  button
                  dense
                  className={classes.nav}
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      style={{
                        backgroundColor: toMaterialStyle(
                          `${profile.email && profile.email}${
                            profile.mobileNumber && profile.mobileNumber
                          }`,
                        ).backgroundColor,
                      }}
                    >
                      {profile.firstName &&
                        profile.firstName.toString().charAt(0)}
                      {!profile.firstName && profile.mobileNumber && '254'}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={profile.firstName}
                    style={{ marginRight: '0.5em' }}
                  />
                  <ListItemIcon style={{ marginRight: '-2em' }}>
                    <KeyboardArrowDownIcon
                      htmlColor={theme.palette.common.white}
                    />
                  </ListItemIcon>
                </ListItem>
              )}

              <IconButton
                onClick={handleDarkMode}
                aria-label="Dark Mode"
                color="inherit"
              >
                {darkMode ? <Brightness5Icon /> : <Brightness2Icon />}
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              {profile && (
                <ListItem
                  alignItems="center"
                  button
                  dense
                  className={classes.nav}
                  aria-label="account of current user"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleProfileMobileMenuOpen}
                  color="inherit"
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      style={{
                        backgroundColor: toMaterialStyle(
                          `${profile.email && profile.email}${
                            profile.mobileNumber && profile.mobileNumber
                          }`,
                        ).backgroundColor,
                      }}
                    >
                      {profile.firstName &&
                        profile.firstName.toString().charAt(0)}
                      {!profile.firstName &&
                        profile.mobileNumber &&
                        profile.mobileNumber.toString().charAt(3)}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={profile.firstName}
                    style={{ marginRight: '0.5em' }}
                  />
                  <ListItemIcon style={{ marginRight: '-2em' }}>
                    <KeyboardArrowDownIcon
                      htmlColor={theme.palette.common.white}
                    />
                  </ListItemIcon>
                </ListItem>
              )}
              <IconButton
                onClick={() => window.location.reload()}
                aria-label="Refresh"
                color="inherit"
              >
                <RefreshIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        <SelectOrganizaton
          selectOrgOpen={selectOrgOpen}
          handleSelectOrgClose={handleSelectOrgClose}
        />
      </div>
    </div>
  );
};

export default Navbar;
