import React, { useEffect, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { getEncryptedQR } from '../../redux/visitor/visitorActions';
import { useMediaQuery } from 'react-responsive';
import { getVisitorHistories } from '../../redux/history/historyActions';
import InstallPWA from './InstallPwa';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));
const CheckInHome = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const qr = useSelector((state) => state.visitor.qr);
  const profile = useSelector((state) => state.visitor.auth);
  const organization = useSelector(
    (state) => state.visitor.authCurrentOrganization,
  );
  const histories = useSelector((state) => state.history.histories);
  const [currentHistory, setCurrentHistory] = useState(null);
  const offlineQr = localStorage.getItem('qr');

  const theme = useTheme();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  useEffect(() => {
    if (currentHistory) {
      dispatch(
        getEncryptedQR({
          timestamp: new Date().getTime(),
          passId: organization.passId,
          destinationId: currentHistory && currentHistory,
        }),
      );
    }
    // eslint-disable-next-line
  }, [currentHistory]);

  useEffect(() => {
    if (profile && organization) {
      dispatch(
        getVisitorHistories({ distinct: true, passId: organization.passId }),
      );
      dispatch(
        getEncryptedQR({
          timestamp: new Date().getTime(),
          passId: organization.passId,
          destinationId: currentHistory && currentHistory,
        }),
      );

      // const interval = setInterval(() => {
      //   if (!navigator.onLine) {
      //     clearInterval(interval);
      //   }
      //   dispatch(
      //     getEncryptedQR({
      //       timestamp: new Date().getTime(),
      //       passId: organization.passId,
      //       destinationId: currentHistory && currentHistory,
      //     }),
      //   );
      //   // Test
      // }, 10000);
      // return () => clearInterval(interval);
    }

    // eslint-disable-next-line
  }, [profile, organization, currentHistory]);

  setInterval(() => {}, 60000);

  return (
    <div className={classes.root}>
      {qr || offlineQr ? (
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5">
              <strong>Visitor Gate Pass</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Show QR Code Below</strong>
            </Typography>
          </Grid>

          {histories && (
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-history-simple">
                  Destination
                </InputLabel>
                <Select
                  fullWidth
                  value={currentHistory || ''}
                  onChange={(e) => setCurrentHistory(e.target.value)}
                  name="historyId"
                  placeholder="Select Destination"
                  label="Select Destination"
                  inputProps={{
                    name: 'historyId',
                    id: 'outlined-history-simple',
                  }}
                >
                  {histories.data &&
                    histories.data.map((history, index) => (
                      <MenuItem key={index} value={history.destinationId}>
                        <em>{history.destination}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <QRCode
              // enableCORS={true}
              value={offlineQr ? offlineQr : qr}
              logoImage={
                organization
                  ? organization.image
                  : `${process.env.PUBLIC_URL}/soja_logo_white_outlined.png`
              }
              logoOpacity={0.97}
              bgColor={theme.palette.background.default}
              size={isTabletOrMobile ? 250 : 350}
              fgColor={theme.palette.primary.main}
              logoHeight={isTabletOrMobile ? 60 : 100}
              logoWidth={isTabletOrMobile ? 60 : 100}
              // qrStyle={'dots'}
            />
          </Grid>
          <Grid item xs={12}>
            <InstallPWA />
          </Grid>
          {/* {organization && (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <strong>Invite Others</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <IconButton
                  component={WhatsappShareButton}
                  url={`https://pass.soja.co.ke/checkin?org=${organization.passId}`}
                  title={`Sign up for an e-Visitors pass *${organization.name}*.\n\n Make your next visit quick and easy. \n\n Powered by soja.co.ke \n\n`}
                >
                  <WhatsAppIcon fontSize="large" />
                </IconButton>
                <IconButton
                  component={EmailShareButton}
                  subject={`Invite for Visitor Pass at ${organization.name}`}
                  url={`https://pass.soja.co.ke/checkin?org=${organization.passId}`}
                  title={`Sign up for an e-Visitors pass ${organization.name}.\n\n Make your next visit quick and easy. \n\n Powered by soja.co.ke \n\n`}
                >
                  <MailIcon fontSize="large" />
                </IconButton>
                <IconButton
                  component={TelegramShareButton}
                  url={`https://pass.soja.co.ke/checkin?org=${organization.passId}`}
                  title={`Sign up for an e-Visitors pass *${organization.name}*.\n\n Make your next visit quick and easy. \n\n Powered by soja.co.ke \n\n`}
                >
                  <TelegramIcon fontSize="large" />
                </IconButton>
              </Grid>
            </React.Fragment>
          )} */}
        </Grid>
      ) : (
        <div className={classes.root}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                style={{ width: '50%' }}
                src={`${process.env.PUBLIC_URL}/pass_logo_bg_outlined.png`}
                alt="Logo"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2">
                <strong>SOJA PASS</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">"Swift,Convenient Checkin"</Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '2rem' }}>
              <Button
                component={Link}
                to="/checkin/profile"
                color="primary"
                variant="contained"
              >
                SETUP PROFILE
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default CheckInHome;
