import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getRoles } from '../../redux/role/roleActions';
import { addUser, addUserAdmin } from '../../redux/user/userActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { getOrganizations } from '../../redux/organization/organizationActions';
import { ROLE_SUPER_ADMIN } from '../../redux/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const AddUser = ({ history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const current = useSelector((state) => state.auth.current);
  const page = useSelector((state) => state.page.page);
  const roles = useSelector((state) => state.role.roles);
  const organizations = useSelector(
    (state) => state.organization.organizations,
  );

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getOrganizations());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      page &&
      page.includes('Add User') &&
      notification &&
      notification.type === 'success'
    ) {
      history.push('/admin/main/users');
    }
    // eslint-disable-next-line
  }, [notification]);

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    roleId: null,
    mobileNumber: '',
    email: '',
    sendMail: false,
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // e.preventDefault();
    if (current && current.roleName === ROLE_SUPER_ADMIN) {
      dispatch(addUserAdmin(form));
    } else {
      dispatch(addUser(form));
    }
  };
  return (
    <Paper className={classes.root}>
      <form onSubmit={onSubmit} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              onChange={onChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              type="number"
              id="mobileNumber"
              label="Mobile Number"
              name="mobileNumber"
              onChange={onChange}
              autoComplete="mobileNumber"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-role-simple">Role</InputLabel>

              <Select
                // inputRef={register}
                name="roleId"
                inputProps={{
                  name: 'roleId',
                  id: 'outlined-role-simple',
                }}
                value={form.roleId}
                onChange={onChange}
              >
                {roles &&
                  roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      <em>{role.name}</em>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          {current && current.roleName === ROLE_SUPER_ADMIN && (
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-organization-simple">
                  Organization
                </InputLabel>
                <Select
                  // inputRef={register}
                  name="organizationId"
                  inputProps={{
                    name: 'organizationId',
                    id: 'outlined-organization-simple',
                  }}
                  value={form.organizationId}
                  onChange={onChange}
                >
                  {organizations &&
                    organizations.data.map((organization) => (
                      <MenuItem key={organization.id} value={organization.id}>
                        <em>{organization.name}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              label={'Send Activation Mail'}
              value={form.sendMail}
              control={
                <Switch
                  color="primary"
                  value={form.sendMail}
                  checked={form.sendMail}
                  onChange={(e) =>
                    setForm({ ...form, sendMail: e.target.checked })
                  }
                />
              }
            />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={
              loading ||
              !form.firstName.length === 0 ||
              !form.lastName.length === 0 ||
              !form.email.length === 0 ||
              !form.mobileNumber.length === 0 ||
              !form.roleId
            }
          >
            Add
          </Button>
        </Grid>
      </form>
    </Paper>
  );
};

export default AddUser;
