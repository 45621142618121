import {
  ADD_ORGANIZATION,
  CLEAR_ORGANIZATION,
  GET_ORGANIZATION,
  GET_ORGANIZATIONS,
  UPDATE_ORGANIZATION,
} from './organizationTypes';

const initialState = {
  current: null,
  error: null,
  loading: false,
  organizations: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case ADD_ORGANIZATION:
      return {
        ...state,
        current: null,
        organizations: {
          ...state.organizations,
          data:
            state.organizations !== null
              ? state.organizations.data.concat(action.payload)
              : [action.payload],
        },
      };
    case GET_ORGANIZATIONS:
      const organizations = action.payload;
      return {
        ...state,
        organizations: organizations,
        current: null,
        loading: false,
      };
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        current: null,
        organizations: {
          ...state.organizations,
          data:
            state.organizations && state.organizations.data.length > 0
              ? state.organizations.data.map(
                  (organization) =>
                    (organization.id = action.payload.id ? action.payload : organization),
                )
              : [],
        },
      };
    case CLEAR_ORGANIZATION:
      return {
        ...state,
        current: null,
        organizations: null,
        loading: false,
      };
    default:
      return state;
  }
};
