import {
  CLEAR_LOADING,
  CLEAR_NOTIFICATION,
  SET_LOADING,
  SET_NOTIFICATION,
} from '../notification/notificationTypes';
import {
  CLEAR_VISITOR,
  DELETE_VISITOR,
  GET_ENCRYPTED_QR,
  GET_VISITOR,
  SET_VISITOR,
  VISITOR_ERROR,
  ADD_VISITORS,
  GET_VISITORS,
  ADD_VISITOR,
  UPDATE_VISITOR,
  GET_VISITOR_ORGANIZATIONS,
} from './visitorTypes';
import axios from 'axios';
import { CLEAR_ORGANIZATION } from '../organization/organizationTypes';
const url = process.env.REACT_APP_API_URL + 'visitors';

export const setVisitor = (profile) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    dispatch({ type: SET_VISITOR, payload: profile });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error);
    dispatch({
      type: VISITOR_ERROR,
      payload: 'Failed to Get Visitor ',
    });
  }
};

export const registerVisitor = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.post(`${url}/register`, data);

    // const visitor = res.data;

    dispatch({ type: SET_VISITOR, payload: res.data });
    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Updated Visitor successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    delete data.idToken;
    localStorage.setItem('profile', JSON.stringify(data));

    const notification = {
      message: `Saved Offline`,
      type: 'info',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    console.log(error);
    dispatch({
      type: VISITOR_ERROR,
      payload: 'Saved Offline.',
    });
  }
};
export const updateVisitor = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.put(`${url}`, data);

    // const visitor = res.data;

    dispatch({ type: SET_VISITOR, payload: res.data });
    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Updated Visitor successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    localStorage.setItem('profile', JSON.stringify(data));

    const notification = {
      message: `Saved Offline`,
      type: 'info',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    console.log(error);
    dispatch({
      type: VISITOR_ERROR,
      payload: 'Saved Offline.',
    });
  }
};

export const updateVisitorAdmin = (data, id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.put(`${url}/${id}`, data);

    // const visitor = res.data;

    dispatch({ type: UPDATE_VISITOR, payload: res.data });
    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Updated Visitor successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
    console.log(error);
  }
};
export const updateVisitorImage = (formData) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.put(`${url}/upload`, formData);

    // const Visitor = res.data;

    dispatch({ type: SET_VISITOR, payload: res.data });
    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Updated Visitor Image`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    localStorage.setItem('profile', JSON.stringify(formData));

    const notification = {
      message: `Saved Offline`,
      type: 'info',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    console.log(error);
    dispatch({
      type: VISITOR_ERROR,
      payload: 'Saved Offline.',
    });
  }
};

export const updateVisitorOrganization = (passId) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.put(`${url}/organization`, { passId });

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: res.data.message,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Failed to Add Organization`,
      type: 'info',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    console.log(error);
    dispatch({
      type: VISITOR_ERROR,
      payload: 'Failed to Add Organization.',
    });
  }
};
export const getEncryptedQR = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.post(`${url}/qr`, data);

    dispatch({ type: GET_ENCRYPTED_QR, payload: res.data });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: VISITOR_ERROR,
      payload: 'Failed to Setup Profile ',
    });
  }
};

export const getCurrentVisitor = (params) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const profile = JSON.parse(localStorage.getItem('profile'));

  try {
    const res = await axios.get(`${url}/current`, { params });

    if (res.data.authType != null) {
      dispatch({ type: SET_VISITOR, payload: res.data });
      dispatch(getVisitorOrganizations());
    }

    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    if (profile) {
      dispatch({ type: SET_VISITOR, payload: profile });
    } else {
      console.log(error);
      dispatch({
        type: VISITOR_ERROR,
        payload: 'Failed to Get Visitor ',
      });
    }
  }
};

// Log Out
export const logOut = () => async (dispatch) => {
  // console.log('Log out');
  try {
    dispatch({ type: CLEAR_VISITOR });
    dispatch({ type: CLEAR_ORGANIZATION });
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Logout Failed', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    dispatch({ type: VISITOR_ERROR, payload: 'Logout failed' });
  }
};

// Get Visitors
export const getVisitors = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const visitors = res.data;
  dispatch({ type: GET_VISITORS, payload: visitors });
  dispatch({ type: CLEAR_LOADING });
};

// Create Visitors
export const addVisitor = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_VISITOR, payload: res.data });
    // Set Register Success Notification

    const visitor = res.data;
    const notification = {
      message: `Create Visitor:  ${visitor.firstName} successfully`,
      type: 'success',
    };
    console.log(visitor);
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: VISITOR_ERROR, payload: 'Failed to Add Visitor' });
  }
};

export const addVisitors = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}/bulk`, data);

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Added ${data.length} visitors successfully`,
      type: 'success',
    };
    dispatch({ type: ADD_VISITORS, payload: res.data });

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: VISITOR_ERROR, payload: 'Failed to Add Visitor' });
  }
};
// Get Selected Visitor By Id
export const getVisitorById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const visitor = res.data;
    dispatch({ type: GET_VISITOR, payload: visitor });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: VISITOR_ERROR, payload: 'Failed to Add Visitor' });
  }
};

// Delete Visitor
export const deleteVisitor = (id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.delete(`${url}/${id}`);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: DELETE_VISITOR, payload: id });
    // Set Register Success Notification

    const notification = {
      message: res.data.message,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: VISITOR_ERROR, payload: 'Failed to Delete Visitor' });
  }
};

export const getVisitorOrganizations = (params) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const res = await axios.get(`${url}/organizations`, { params });

    dispatch({ type: GET_VISITOR_ORGANIZATIONS, payload: res.data });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: VISITOR_ERROR,
      payload: 'Failed to Get Organizations',
    });
  }
};
