import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_INVITE,
  DELETE_INVITE,
  GET_INVITE,
  GET_INVITES,
  INVITE_ERROR,
  UPDATE_INVITE,
} from './inviteTypes';

const url = process.env.REACT_APP_API_URL + 'invite';

let notification;

// Create Invite

export const addInvite = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: ADD_INVITE, payload: res.data });

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    notification = {
      message: `Invite Generated`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: INVITE_ERROR,
      payload: 'Failed to Send Invite',
    });
  }
};

export const addInviteAdmin = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}/admin`, data);

    dispatch({ type: ADD_INVITE, payload: res.data });

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    notification = {
      message: `Invite Generated`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: INVITE_ERROR,
      payload: 'Failed to Send Invite',
    });
  }
};

// Update Invite
export const updateInvite = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: UPDATE_INVITE, payload: res.data });
    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Updated Invite successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: INVITE_ERROR,
      payload: 'Failed to Update Invite',
    });
  }
};

// Get Invites
export const getInvites = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const invites = res.data;
  dispatch({ type: GET_INVITES, payload: invites });
  dispatch({ type: CLEAR_LOADING });
  setTimeout(() => {
    dispatch({ type: CLEAR_NOTIFICATION });
  }, 1000);
};

// Get Invite  By ID
export const getInviteByID = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const invite = res.data;
    dispatch({ type: GET_INVITE, payload: invite });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: INVITE_ERROR,
      payload: 'Failed to Get Invite',
    });
  }
};

// Get Invite  By ID
export const getVisitorInviteByID = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/visitor/${id}`);
    const invite = res.data;
    if (invite!=null && invite.toString().length>0) {
      dispatch({ type: GET_INVITE, payload: invite });
    }
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: INVITE_ERROR,
      payload: 'Failed to Get Invite',
    });
  }
};

// Delete Invite
export const deleteInvite = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    const notification = {
      message: `Deleted Invite`,
      type: 'info',
    };

    dispatch({ type: DELETE_INVITE, payload: id });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: INVITE_ERROR,
      payload: 'Failed to Delete Invite',
    });
  }
};
