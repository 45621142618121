import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import {
  getMessageTemplateById,
  sendMessageTemplate,
  updateMessageTemplateDefault,
  deleteMessageTemplate,
} from '../../../redux/message-template/messageTemplateActions';
import Fade from '@material-ui/core/Fade';
import { MESSAGE_TYPES } from '../../../redux/message-template/messageTemplateTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  card: {
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardContent: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  tabs: {
    flexGrow: 1,
  },
}));

const MessageTemplateView = ({ match, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMessageTemplateById(match.params.id));
    // eslint-disable-next-line
  }, []);

  const current = useSelector((state) => state.messageTemplate.current);

  const notification = useSelector((state) => state.notification.notification);

  // const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action) => {
    setAnchorEl(null);
    if (action === 'edit') {
      history.push(`/admin/main/message-template/edit/${match.params.id}`);
    } else if (action === 'delete') {
      setShowDialogDelete(true);
    }
  };

  const [showDialogSend, setShowDialogSend] = useState(false);

  const [showDialogDelete, setShowDialogDelete] = useState(false);

  const handleSendMessageTemplate = () => {
    dispatch(sendMessageTemplate(current.id));
    setShowDialogSend(false);
  };
  const handleDeleteMessageTemplate = () => {
    dispatch(deleteMessageTemplate(current.id));
    setShowDialogDelete(false);
  };

  const handleShowDialogClose = () => {
    setShowDialogSend(false);
  };

  const handleShowDialogDeleteClose = () => {
    setShowDialogDelete(false);
  };
  useEffect(() => {
    if (
      page &&
      page.includes('Message Templates') &&
      notification &&
      notification.type === 'info'
    ) {
      window.location.reload();
    }

    if (
      page &&
      page.includes('Message Templates') &&
      notification &&
      notification.type === 'warning'
    ) {
      history.push(`/admin/main/message-templates`);
    }
    // eslint-disable-next-line
  }, [notification]);

  return (
    <div className={classes.root}>
      {current && (
        <Grid container spacing={3}>
          <Card className={classes.card}>
            <CardHeader
              action={
                <IconButton onClick={handleClick} aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title={current.name}
            />

            <Menu
              id="fade-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => handleClose('edit')}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                  Edit
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleClose('delete')}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                  Delete
                </Typography>
              </MenuItem>
            </Menu>
            <CardContent>
              <Grid
                className={classes.cardContent}
                container
                direction="column"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Subject</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {current.subject}
                  </Typography>
                </Grid>
              </Grid>
              {current.organizationId && (
                <Grid
                  className={classes.cardContent}
                  container
                  direction="column"
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <strong>Organization</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Chip
                      color="secondary"
                      style={{ cursor: 'pointer' }}
                      label={current.organizationName}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                className={classes.cardContent}
                container
                direction="column"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Message Group</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Chip
                    color="primary"
                    component={Link}
                    style={{ cursor: 'pointer' }}
                    label={current.messageGroup || 'None'}
                    to={
                      current.messageGroup
                        ? `/admin/main/message-group/view/${current.messageGroupId}`
                        : `/admin/main/message-group`
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                className={classes.cardContent}
                container
                direction="column"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Mode</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" color="textSecondary">
                    {current.mode}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                className={classes.cardContent}
                container
                direction="column"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Message Type</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {current.type.split('_').join(' ')}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card className={classes.card} style={{ marginTop: '1rem' }}>
            <CardHeader subheader="Actions" />

            <CardContent>
              <Grid container spacing={2}>
                {current.type === MESSAGE_TYPES.NONE && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={() => setShowDialogSend(true)}
                      endIcon={<SendIcon />}
                    >
                      Send
                    </Button>
                  </Grid>
                )}

                {current.type !== MESSAGE_TYPES.NONE && (
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        dispatch(
                          updateMessageTemplateDefault(
                            current.id,
                            !current.typeDefault,
                          ),
                        )
                      }
                      color={!current.typeDefault ? 'primary' : 'secondary'}
                    >
                      {' '}
                      {current.typeDefault
                        ? `Disable Default for ${current.type
                            .split('_')
                            .join(' ')}`
                        : `Enable Default for ${current.type
                            .split('_')
                            .join(' ')}`}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}

      <Dialog
        open={showDialogSend}
        onClose={handleShowDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Message Send</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to send this template to all recipients ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendMessageTemplate} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handleShowDialogClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Handle Delete */}
      <Dialog
        open={showDialogDelete}
        onClose={handleShowDialogDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Message Template Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this messsage template ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteMessageTemplate}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
          <Button onClick={handleShowDialogDeleteClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MessageTemplateView;
