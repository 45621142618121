import {
  GET_MESSAGE_TEMPLATES,
  ADD_MESSAGE_TEMPLATE,
  REMOVE_MESSAGE_TEMPLATE,
  UPDATE_MESSAGE_TEMPLATE,
  GET_MESSAGE_TEMPLATE,
  UPDATE_MESSAGE_TEMPLATE_JOB,
} from './messageTemplateTypes';

const initialState = {
  messageTemplates: null,
  current: null,
  error: null,
  loading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGE_TEMPLATES:
      return {
        ...state,
        messageTemplates: action.payload,
      };

    case ADD_MESSAGE_TEMPLATE:
      return {
        ...state,
        messageTemplates: {
          ...state.messageTemplates,
          data:
            state.messageTemplates && state.messageTemplates.count > 0
              ? state.messageTemplates.data.concat(action.payload)
              : [action.payload],
        },
      };
    case REMOVE_MESSAGE_TEMPLATE:
      return {
        ...state,
        messageTemplates: {
          ...state.messageTemplates,
          data:
            state.messageTemplates &&
            state.messageTemplates.data.filter(
              (messageTemplate) => messageTemplate.id !== action.payload,
            ),
        },
        current: null,
      };
    case UPDATE_MESSAGE_TEMPLATE:
      return {
        ...state,
        messageTemplates: {
          ...state.messageTemplates,
          data:
            state.messageTemplates &&
            state.messageTemplates.count > 0 &&
            state.messageTemplates.data.map(
              (messageTemplate) =>
                (messageTemplate.id = action.payload.id
                  ? action.payload
                  : messageTemplate),
            ),
        },
      };
    case UPDATE_MESSAGE_TEMPLATE_JOB:
      return {
        ...state,
        messageTemplates:
          state.messageTemplates &&
          state.messageTemplates.data.map(
            (messageTemplate) =>
              (messageTemplate.id = action.payload.id
                ? { ...messageTemplate, periodicActive: action.payload.active }
                : messageTemplate),
          ),
        // current: { ...state.messageTemplate, periodicActive: action.payload.active }
      };
    case GET_MESSAGE_TEMPLATE:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};
