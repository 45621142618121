import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MUIDataTable from 'mui-datatables';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@material-ui/pickers';
import CustomSearchRender from '../../utils/CustomSearchRender';
import { getHistories } from '../../redux/history/historyActions';
import { format, parseISO } from 'date-fns/esm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const CheckInHistory = ({ history }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const dispatch = useDispatch();
  const histories = useSelector((state) => state.history.histories);

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return { name, direction };
  };

  const handleFromDateChange = (date) =>
    setFilter({
      ...filter,
      createdDateFrom: date,
    });
  const handleToDateChange = (date) =>
    setFilter({ ...filter, createdDateTo: date });

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortOrder: getSortOrder('id'),
        print: false,
        filter: false,
        download: false,
      },
    },

    {
      name: 'destination',
      label: 'Destination',
      options: {
        sortOrder: getSortOrder('destination'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'destinationId',
      label: 'Destination',
      options: {
        display: false,
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Entry Time',
      options: {
        display: true,
        filter: true,
        sortOrder: getSortOrder('createdAt'),
        filterType: 'custom',
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'p dd-MM-yyyy'
              )}
            </Typography>
          );
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            if (filters[0] && filters[1]) {
              return date < filters[0] || date > filters[1];
            } else if (filters[0]) {
              return date < filters[0];
            } else if (filters[1]) {
              return date > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Entry Time</FormLabel>

              <Grid container>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="From"
                    inputVariant="outlined"
                    value={filter.createdDateFrom || new Date()}
                    onChange={(date) => {
                      handleFromDateChange(date);
                      filterList[index][0] = date.toString();
                      onChange(filterList[index], index, column);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="To"
                    inputVariant="outlined"
                    value={filter.createdDateTo || new Date()}
                    onChange={(date) => {
                      filterList[index][1] = date.toString();
                      handleToDateChange(date);
                      onChange(filterList[index], index, column);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          ),
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    serverSide: true,
    rowHover: false,
    downloadOptions: { filename: 'histories.csv', useDisplayedRowsOnly: false },
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    count: histories && histories.count,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },
    fixedHeader: true,
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      //
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction,
      });
    },
    selectableRows: 'none',
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getHistories(filter));

    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (histories) {
      var finalData =
        histories &&
        histories.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [histories]);

  return (
    <div>
      <Paper className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {data && (
              <MUIDataTable data={data} columns={columns} options={options} />
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default CheckInHistory;
