import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addOrganization } from '../../redux/organization/organizationActions';

const AddOrganizationDialog = ({ addOrgOpen, handleAddOrgClose }) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.notification.loading);

  const [passId, setPassId] = useState(null);
  const handleSubmit = () => {
    dispatch(addOrganization({ passId }));
    handleAddOrgClose();
  };

  return (
    <Dialog
      open={addOrgOpen}
      onClose={handleAddOrgClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Organization</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To add organization enter Pass ID here.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Pass ID"
          type="text"
          onChange={(e) => setPassId(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={passId == null || loading}
          onClick={handleSubmit}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrganizationDialog;
