import React, { useEffect, useState } from 'react';
import { makeStyles, lighten } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MUIDataTable from 'mui-datatables';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import CustomSearchRender from '../../../utils/CustomSearchRender';
import { parseISO } from 'date-fns';
import { format } from 'date-fns/esm';
import { getHistories } from '../../../redux/history/historyActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  labelRow: {
    backgroundColor: `${lighten(theme.palette.secondary.main, 0.6)}`,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(1),
    borderRadius: '0.5em',
    textDecoration: 'none',
  },
}));

const VisitorHistory = ({ history ,id}) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);

  const dispatch = useDispatch();

  const histories = useSelector((state) => state.history.histories);
  // const notification = useSelector((state) => state.notification.notification);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 25,
    search: null,
    sort: null,
    sortOrder: null,
    visitorId:id
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return { name, direction };
  };
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        sortOrder: getSortOrder('id'),
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        sortOrder: getSortOrder('name'),
        display: true,
      },
    },
    {
      name: 'image',
      label: 'Image',
      options: {
        display: false,
      },
    },
    {
      name: 'passId',
      label: 'Pass ID',
      options: {
        display: false,
      },
    },
    {
      name: 'primaryColor',
      label: 'Primary Color',
      options: {
        display: false,
      },
    },
    {
      name: 'secondaryColor',
      label: 'Secondary Color',
      options: {
        display: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        display: true,
        filter: true,
        sortOrder: getSortOrder('createdAt'),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1" color="primary">
              {format(parseISO(value, 'yyyy-MM-dd', new Date()), 'do MMM yyyy')}
            </Typography>
          );
        },
      },
    },
    {
      name: 'updatedAt',
      label: 'updatedAt',
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    serverSide: true,
    downloadOptions: {
      filename: 'history.csv',
      useDisplayedRowsOnly: false,
    },
    elevation:0,
    selectableRows: 'none',
    rowHover: false,
    onTableChange: (action, tableState) => {},
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage });
    },
    rowsPerPage: 1,
    rowsPerPageOptions: [1, 10, 20, 30, 50, 100],
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction,
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log('Here');
    dispatch(getHistories(filter));

    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (histories) {
      var finalData =
        histories &&
        histories.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [histories]);



  return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {data && (
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </Grid>
        </Grid>
      </div>
  );
};

export default VisitorHistory;
