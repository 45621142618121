export const ADD_ROLE = 'ADD_ROLE';
export const GET_ROLE = 'GET_ROLE';
export const GET_ROLES = 'GET_ROLES';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';

export const ROLE_SUPER_ADMIN = 'Superadmin';
export const ROLE_ORG_ADMIN = 'Orgadmin';
export const ROLE_MANAGEMENT = 'Management';
export const ROLE_ADMIN = 'Admin';
export const ROLE_WORKER = 'Worker';
