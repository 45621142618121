import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { saveCurrentUser } from '../../redux/auth/authActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const SettingsProfile = () => {
  const dispatch = useDispatch();
  // const loading = useSelector((state) => state.notification.loading);
  // const page = useSelector((state) => state.page.page);
  // const notification = useSelector((state) => state.notification.notification);
  const current = useSelector((state) => state.auth.current);
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    roleId: 'None',
    roleName: '',
  });
  const classes = useStyles();

  useEffect(() => {
    // getRoles();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (current && current.firstName) {
      profile.firstName = current.firstName;
      profile.lastName = current.lastName;
      profile.email = current.email && current.email;
      profile.roleId = current.roleId;
      profile.roleName = current.roleName;
      profile.mobileNumber = current.mobileNumber;

      setProfile({ ...profile, profile });
    }
    // eslint-disable-next-line
  }, [current]);

  const onChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
    // changeSettings(profile);
  };

  useEffect(() => {
    // updateCurrentUser(profile);
    // eslint-disable-next-line
  }, [profile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(saveCurrentUser(profile));
  };
  return (
    <Paper>
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          padding: '1rem',
        }}
      >
        <div style={{ marginTop: 16 }}>
          <TextField
            id="firstName"
            name="firstName"
            value={profile.firstName}
            label="First Name"
            variant="outlined"
            onChange={onChange}
            fullWidth
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <TextField
            id="lastName"
            name="lastName"
            variant="outlined"
            label="Last Name"
            onChange={onChange}
            value={profile.lastName}
            fullWidth
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            name="email"
            value={profile.email}
            onChange={onChange}
            fullWidth
            disabled
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <TextField
            id="mobile Number"
            label="Mobile Number"
            variant="outlined"
            name="mobileNumber"
            value={profile.mobileNumber}
            onChange={onChange}
            fullWidth
            disabled
          />
        </div>

        <div style={{ marginTop: 16 }}>
          <TextField
            id="roleName"
            label="Role"
            variant="outlined"
            name="roleName"
            value={profile.roleName}
            // onChange={onChange}
            disabled
            fullWidth
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          className={classes.button}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
      </form>
    </Paper>
  );
};

export default SettingsProfile;
