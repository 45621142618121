import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getUserById } from '../../../redux/user/userActions';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ProfileSettingsView from './ProfileSettingsView';
import { getRoles } from '../../../redux/role/roleActions';
import AccountSettingsView from './AccountSettingsView';
import toMaterialStyle from 'material-color-hash';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
    margin: theme.spacing(10, 2),
  },
  card: {
    padding: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  contentColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  labelBadge: {
    background: theme.palette.primary.light,
    padding: theme.spacing(0.3),
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
  },
}));

const UserView = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const current = useSelector((state) => state.user.current);
  const roles = useSelector((state) => state.role.role);

  useEffect(() => {
    dispatch(getUserById(match.params.id));
    dispatch(getRoles());
    // eslint-disable-next-line
  }, []);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!current) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardContent>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Avatar
                      alt={current.firstName}
                      className={classes.avatar}
                      style={{
                        backgroundColor: toMaterialStyle(
                          `${current.firstName} ${current.lastName}`,
                          '250',
                        ).backgroundColor,
                        fontSize: '5em',
                      }}
                    >
                      {current.firstName.charAt(0)}
                    </Avatar>
                  </Grid>
                  <Grid item xs={12} className={classes.contentColumn}>
                    <Typography variant="h6" className={classes.labelBadge}>
                      {current && current.firstName}{' '}
                      {current && current.lastName}
                    </Typography>
                    <br />
                    <Typography variant="body1">
                      {current && current.email}
                    </Typography>
                    <br />

                    <Typography variant="body1" className={classes.labelBadge}>
                      <strong>{current && current.mobileNumber}</strong>
                    </Typography>
                    <br />
                    <Typography variant="body1" className={classes.labelBadge}>
                      <strong>{current && current.organizationName}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={9}>
            <Paper>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                variant="fullWidth"
              >
                <Tab label="Profile" />
                <Tab label="Account" />
                {/* <Tab label="Notifications" /> */}
                {/* <Tab label="Item Two" />
          <Tab label="Item Three" /> */}
              </Tabs>

              <div>
                {value === 0 && (
                  <div>
                    <ProfileSettingsView user={current} />
                  </div>
                )}
              </div>
              <div>
                {value === 1 && (
                  <div>
                    <AccountSettingsView roles={roles} user={current} />
                  </div>
                )}
              </div>
              {/* <div>{value === 2 && <div>Hello Notifications</div>}</div> */}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default UserView;
