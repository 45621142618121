import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  updateUserRole,
  updateUserOrganization,
  updateUserStatus,
  resetUserPassword,
} from '../../../redux/user/userActions';
import {
  ROLE_ORG_ADMIN,
  ROLE_SUPER_ADMIN,
} from '../../../redux/role/roleTypes';
import { getOrganizations } from '../../../redux/organization/organizationActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const AccountSettingsView = ({ user, history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const roles = useSelector((state) => state.role.roles);
  const organizations = useSelector(
    (state) => state.organization.organizations,
  );
  const currentUser = useSelector((state) => state.auth.current);

  const [roleId, setRoleId] = useState('');
  const [organizationId, setOrganizationId] = useState('');

  useEffect(() => {
    setEnabled(user.isEnabled);
    setRoleId(user.roleId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(getOrganizations());
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (organizations) {
      setOrganizationId(user.organizationId);
    }
    // eslint-disable-next-line
  }, [organizations]);

  const handleEnableUser = (e) => {
    setEnabled(!isEnabled);
    dispatch(updateUserStatus(user.id, !isEnabled));
  };

  const handleChangeRole = () => {
    setConfirmRoleChangeOpen(false);
    dispatch(updateUserRole(user.id, roleId));
  };
  const handleChangeOrganization = () => {
    setConfirmOrganizationChangeOpen(false);
    dispatch(updateUserOrganization(user.id, organizationId));
  };

  const handleResetPassword = () => {
    setResetPasswordOpen(false);
    dispatch(resetUserPassword(user.id));
  };

  const [isEnabled, setEnabled] = useState(false);

  const [confirmRoleChangeOpen, setConfirmRoleChangeOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [
    confirmOrganizationChangeOpen,
    setConfirmOrganizationChangeOpen,
  ] = useState(false);

  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          {currentUser && currentUser.roleName === ROLE_SUPER_ADMIN && (
            <Button variant="outlined" onClick={handleResetPassword}>
              Reset Password
            </Button>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              label={isEnabled ? 'Enabled' : 'Disabled'}
              value={isEnabled ? 'Enabled' : 'Disabled'}
              control={
                <Switch
                  color="primary"
                  value={isEnabled ? 'Enabled' : 'Disabled'}
                  checked={isEnabled}
                  onChange={handleEnableUser}
                />
              }
            />
          </Grid>

          {roleId && user && user.roleName !== ROLE_ORG_ADMIN && (
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-role-simple">Role</InputLabel>
                <Select
                  value={roleId}
                  onChange={(e) => {
                    setRoleId(e.target.value);
                    setConfirmRoleChangeOpen(true);
                  }}
                  inputProps={{
                    name: 'roleId',
                    id: 'outlined-role-simple',
                  }}
                >
                  {roles &&
                    roles.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        <em>{role.name}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {currentUser && currentUser.roleName === ROLE_SUPER_ADMIN && (
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-organization-simple">
                  Organization
                </InputLabel>
                <Select
                  // inputRef={register}
                  name="organizationId"
                  inputProps={{
                    name: 'organizationId',
                    id: 'outlined-organization-simple',
                  }}
                  value={organizationId}
                  onChange={(e) => {
                    setOrganizationId(e.target.value);
                    setConfirmOrganizationChangeOpen(true);
                  }}
                >
                  {organizations &&
                    organizations.data.map((organization) => (
                      <MenuItem key={organization.id} value={organization.id}>
                        <em>{organization.name}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Dialog Confirm Role Change */}
      <Dialog
        open={confirmRoleChangeOpen}
        // TransitionComponent={Transition}
        // keepMount  ed
        onClose={() => {
          setConfirmRoleChangeOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Change Role
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to change {user.firstName} role to{' '}
            {roles.map((role) => (role.id === roleId ? role.name : ''))} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleChangeRole();
            }}
            color="primary"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setConfirmRoleChangeOpen(false);
            }}
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Confirm Organization Change */}
      {organizations && (
        <Dialog
          open={confirmOrganizationChangeOpen}
          onClose={() => {
            setConfirmOrganizationChangeOpen(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title-organizaion">
            Confirm Change Organization
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to change {user.firstName} organization to{' '}
              {organizations.data.map((organization) =>
                organization.id === organizationId ? organization.name : '',
              )}{' '}
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleChangeOrganization();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setConfirmOrganizationChangeOpen(false);
              }}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/*  */}
      {/* Dialog Confirm Reset Change */}
      <Dialog
        open={resetPasswordOpen}
        // TransitionComponent={Transition}
        // keepMount  ed
        onClose={() => {
          setResetPasswordOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to reset {user.firstName} password ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetPassword} color="primary">
            Yes
          </Button>
          <Button
            onClick={() => {
              setResetPasswordOpen(false);
            }}
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      {/*  */}
    </Paper>
  );
};

export default AccountSettingsView;
