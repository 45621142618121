import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SubjectIcon from '@material-ui/icons/Subject';
import { TiSortAlphabetically } from 'react-icons/ti';
import { useSelector, useDispatch } from 'react-redux';
import {
  addMessageTemplate,
  addMessageTemplateAdmin,
} from '../../redux/message-template/messageTemplateActions';
import { MESSAGE_TYPES } from '../../redux/message-template/messageTemplateTypes';
import { getMessageGroups } from '../../redux/message-group/messageGroupActions';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { getOrganizations } from '../../redux/organization/organizationActions';
import { ROLE_SUPER_ADMIN } from '../../redux/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const AddMessageTemplate = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();

  const messageGroups = useSelector(
    (state) => state.messageGroup.messageGroups,
  );
  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const currentUser = useSelector((state) => state.auth.current);
  const organizations = useSelector(
    (state) => state.organization.organizations,
  );

  const page = useSelector((state) => state.page.page);

  const [form, setForm] = useState({
    name: '',
    mode: 'sms',
    content: '',
    subject: '',
    messageGroupId: null,
    type: MESSAGE_TYPES.NONE,
    typeDefault: false,
    organizationId: null,
  });

  useEffect(() => {
    dispatch(getMessageGroups());
    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    if (e.target.value !== 'None') {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Add Message Template') &&
      notification &&
      notification.type === 'success'
    ) {
      history.push('/admin/main/message-template');
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.content = htmlContent;

    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(addMessageTemplateAdmin(form));
    } else {
      dispatch(addMessageTemplate(form));
    }
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlContent, setHtmlContent] = useState('');

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    if (form) {
      generateSuggestions();
    }
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    if (editorState) {
      const html = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      ).replace(/(href=".*?")/g, '');

      setHtmlContent(html);
    }
  }, [editorState]);

  // Change User Suggestions
  const [userType, setUserType] = useState('visitor');

  useEffect(() => {
    setForm({ ...form, content: '' });
    setEditorState(EditorState.createEmpty());
    // eslint-disable-next-line
  }, [userType]);

  // Suggestions

  const [suggestions, setSuggestions] = useState([]);

  const generateSuggestions = () => {
    let suggestions = [
      { text: 'First Name', value: `${userType}.firstName` },
      { text: 'Last Name', value: `${userType}.lastName` },
      { text: 'Mobile Number', value: `${userType}.mobileNumber` },
      { text: 'Password Reset Link', value: `${userType}.passwordResetLink` },
      { text: 'User Activate Link', value: `${userType}.activateLink` },
    ];
    suggestions = suggestions.concat(getOptionalSuggestions());
    setSuggestions(suggestions);
  };

  const getOptionalSuggestions = () => {
    let optionalSuggestions = [];
    if (form.type === MESSAGE_TYPES.INVITE) {
      optionalSuggestions = optionalSuggestions.concat(
        {
          text: 'Invite Contact',
          value: 'invite.contact',
        },
        {
          text: 'Invite Link',
          value: 'invite.link',
        },
      );
    }
    return optionalSuggestions;
  };

  useEffect(() => {
    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(getOrganizations());
    }
    // eslint-disable-next-line
  }, [currentUser]);

  if (!messageGroups) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid container direction="row" spacing={2}>
              {currentUser && currentUser.roleName === ROLE_SUPER_ADMIN && (
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-organization-simple">
                      Select Organization
                    </InputLabel>
                    <Select
                      // inputRef={register}
                      name="organizationId"
                      inputProps={{
                        name: 'organizationId',
                        id: 'outlined-organization-simple',
                      }}
                      value={form.organizationId}
                      onChange={(e) =>
                        setForm({ ...form, organizationId: e.target.value })
                      }
                    >
                      {organizations &&
                        organizations.data.map((organization) => (
                          <MenuItem
                            key={organization.id}
                            value={organization.id}
                          >
                            <em>{organization.name}</em>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-userType-simple">
                    Select User Type
                  </InputLabel>
                  <Select
                    fullWidth
                    value={userType}
                    onChange={(e) => {
                      setUserType(e.target.value);
                    }}
                    name="userType"
                    inputProps={{
                      name: 'userType',
                      id: 'outlined-userType-simple',
                    }}
                  >
                    <MenuItem value="visitor">
                      <em>Visitor</em>
                    </MenuItem>
                    <MenuItem value="user">
                      <em>User</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={form.name}
                  name="name"
                  onChange={onChange}
                  id="name"
                  label="Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TiSortAlphabetically size={18} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-mode-simple">
                    Select Mode
                  </InputLabel>
                  <Select
                    fullWidth
                    value={form.mode}
                    onChange={onChange}
                    name="mode"
                    inputProps={{
                      name: 'mode',
                      id: 'outlined-mode-simple',
                    }}
                  >
                    <MenuItem value="sms">
                      <em>SMS</em>
                    </MenuItem>
                    <MenuItem value="email">
                      <em>Email</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-message-type-simple">
                    Select Message Type
                  </InputLabel>

                  <Select
                    fullWidth
                    value={form.type || MESSAGE_TYPES.NONE}
                    onChange={onChange}
                    name="type"
                    inputProps={{
                      name: 'type',
                      id: 'outlined-message-type-simple',
                    }}
                  >
                    {Object.entries(MESSAGE_TYPES).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        <em>{value.split('_').join(' ')}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-message-group-simple">
                    Select Message Group
                  </InputLabel>
                  <Select
                    fullWidth
                    value={form.messageGroupId || 'None'}
                    onChange={onChange}
                    name="messageGroupId"
                    inputProps={{
                      name: 'messageGroupId',
                      id: 'outlined-message-group-simple',
                    }}
                  >
                    <MenuItem key={0} value="None">
                      <em>None</em>
                    </MenuItem>
                    {messageGroups &&
                      messageGroups.data.map((messageGroup) => (
                        <MenuItem key={messageGroup.id} value={messageGroup.id}>
                          <em>{messageGroup.name}</em>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={form.subject}
                  name="subject"
                  onChange={onChange}
                  id="subject"
                  label="Subject"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SubjectIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  onEditorStateChange={onEditorStateChange}
                  placeholder="Enter Message Content"
                  editorStyle={{
                    border: `1px solid ${theme.palette.primary.light}`,
                    padding: '1rem',
                    borderRadius: '5px',
                  }}
                  mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions,
                  }}
                  hashtag={{}}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              style={{ marginTop: '2rem' }}
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                !htmlContent ||
                !form.name ||
                !form.type ||
                !form.mode ||
                !form.subject ||
                loading
              }
            >
              Add Message Template
            </Button>
          </Grid>
        </form>
      </Paper>
    );
  }
};

export default AddMessageTemplate;
