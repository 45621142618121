import {
  GET_HISTORIES,
  GET_HISTORY,
  ADD_HISTORY,
  REMOVE_HISTORY,
} from './historyTypes';

const initialState = {
  current: null,
  error: null,
  loading: false,
  histories: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case ADD_HISTORY:
      return {
        ...state,
        current: action.payload,
        histories: {
          ...state.histories,
          data:
            state.histories !== null
              ? state.histories.data.concat(action.payload)
              : [action.payload],
        },
      };
    case GET_HISTORIES:
      const histories = action.payload;
      return {
        ...state,
        histories: histories,
        current:
          histories.data.length > 0 && !state.current
            ? histories.data[0]
            : state.current,
        loading: false,
      };
    case REMOVE_HISTORY:
      return {
        ...state,
        current: null,
        histories: null,
        loading: false,
      };
    default:
      return state;
  }
};
