import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import historyReducer from './history/historyReducer';
import messageGroupReducer from './message-group/messageGroupReducer';
import messageReducer from './message/messageReducer';
import messageTemplateReducer from './message-template/messageTemplateReducer';
import notificationReducer from './notification/notificationReducer';
import organizationReducer from './organization/organizationReducer';
import pagesReducer from './pages/pagesReducer';
import serviceWorkerReducer from './service-worker/serviceWorkerReducer';
import visitorReducer from './visitor/visitorReducer';
import userReducer from './user/userReducer';
import roleReducer from './role/roleReducer';
import integrationReducer from './integration/integrationReducer';
import inviteReducer from './invite/inviteReducer';
import settingsReducer from './setting/settingsReducer';
export default combineReducers({
  auth: authReducer,
  organization: organizationReducer,
  history: historyReducer,
  visitor: visitorReducer,
  user: userReducer,
  role: roleReducer,
  integration: integrationReducer,
  notification: notificationReducer,
  page: pagesReducer,
  messageTemplate: messageTemplateReducer,
  message: messageReducer,
  messageGroup: messageGroupReducer,
  invite: inviteReducer,
  setting:settingsReducer,
  serviceWorker: serviceWorkerReducer,
});
