import { SET_CURRENT_PAGE, CLEAR_CURRENT_PAGE } from './pagesTypes';

// Set Current Page

export const setCurrentPage = (path) => async (dispatch) => {
  const page = convertPathToPage(path.toString().toLowerCase());
  // console.log(page);

  dispatch({ type: SET_CURRENT_PAGE, payload: page });
};
// Clear Current Page

export const clearCurrentPage = async (dispatch) => {
  dispatch({ type: CLEAR_CURRENT_PAGE });
};

const convertPathToPage = (path) => {
  if (path.includes('/checkin/profile/upload')) {
    return 'Upload Profile';
  }
  if (path.includes('/checkin/profile')) {
    return 'Profile';
  }
  if (path.includes('/checkin/history')) {
    return 'History';
  }

  if (path.includes('/signin')) {
    return 'Sign In';
  }

  if (path.includes('/organizations/edit')) {
    return 'Edit Organization';
  }

  if (path.includes('/organizations')) {
    return 'Organizations';
  }

  if (path.includes('/users/add')) {
    return 'Add User';
  }
  if (path.includes('/users/edit')) {
    return 'Update User';
  }

  if (path.includes('/users')) {
    return 'Users';
  }
  if (path.includes('/visitors/add')) {
    return 'Add Visitor';
  }
  if (path.includes('/visitors/edit')) {
    return 'Update Visitor';
  }

  if (path.includes('/visitors')) {
    return 'Visitors';
  }

  if (path.includes('/message-template/add')) {
    return 'Add Message Template';
  }
  if (path.includes('/message-template/edit')) {
    return 'Edit Message Template';
  }

  if (path.includes('/message-template')) {
    return 'Message Templates';
  }


  if (path.includes('/message-group/add')) {
     return 'Add Message Group';
  }
  if (path.includes('/message-group/edit')) {
    return 'Edit Message Group';
  }

  if (path.includes('/message-group')) {
     return 'Message Groups';
  }

  if (path.includes('/invites')) {
    return 'Invites';
  }

  if (path.includes('/messaging/send')) {
    return 'Send Message';
  }

  if (path.includes('/messaging')) {
    return 'Messaging';
  }

  if (path.includes('/settings/integrations')) {
    return 'Integrations';
  }
  if (path.includes('/settings/account')) {
    return 'Account';
  }
  if (path.includes('/settings/organization')) {
    return 'Organization';
  }
  if (path.includes('/settings/options')) {
    return 'Options';
  }
  if (path.includes('/settings')) {
    return 'Settings';
  }
};
