import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { addVisitor } from '../../redux/visitor/visitorActions';
import { AUTH_TYPE } from '../../redux/visitor/visitorTypes';
import { setNotification } from '../../redux/notification/notificationActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const AddVisitor = ({ history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);

  useEffect(() => {
    if (
      page &&
      page.includes('Add Visitor') &&
      notification &&
      notification.type === 'success'
    ) {
      history.push('/admin/main/visitors');
    }
    // eslint-disable-next-line
  }, [notification]);

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    authType: AUTH_TYPE.PHONE,
    sendInvite: false,
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (form.firstName.length === 0 || form.lastName.length === 0) {
      dispatch(
        setNotification({
          message: `Enter First and Last Name`,
          type: 'warning',
        }),
      );
      return;
    }
    if (form.authType === AUTH_TYPE.PHONE && form.mobileNumber.length === 0) {
      dispatch(
        setNotification({
          message: `Phone No Cannot be Empty`,
          type: 'warning',
        }),
      );
      return;
    }
    if (form.authType === AUTH_TYPE.GMAIL && form.email.length === 0) {
      dispatch(
        setNotification({ message: `Email Cannot be Empty`, type: 'warning' }),
      );
      return;
    }
    if (!form.email.endsWith('gmail.com')) {
      dispatch(
        setNotification({
          message: `Email must be a Gmail address`,
          type: 'warning',
        }),
      );
      return;
    }
    // e.preventDefault();
    dispatch(addVisitor(form));
  };
  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <Paper className={classes.root}>
      <form onSubmit={onSubmit} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              onChange={onChange}
              autoComplete="lname"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              type="number"
              id="mobileNumber"
              label="Mobile Number"
              name="mobileNumber"
              autoComplete="phoneNumber"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-authType-simple">
                Select Login Type
              </InputLabel>
              <Select
                fullWidth
                value={form.authType}
                onChange={onChange}
                name="authType"
                inputProps={{
                  name: 'authType',
                  id: 'outlined-authType-simple',
                }}
              >
                {Object.entries(AUTH_TYPE).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    <em>{value.split('_').join(' ')}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={form.sendInvite}
                  onChange={(e) =>
                    setForm({ ...form, sendInvite: e.target.checked })
                  }
                  value="sendInvite"
                  name="sendInvite"
                  color="primary"
                />
              }
              value={form.sendInvite}
              label="Send Pass Invite"
            />
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            color="primary"
            className={classes.submit}
          >
            Add
          </Button>
        </Grid>
      </form>
    </Paper>
  );
};

export default AddVisitor;
