import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Search from '@material-ui/icons/Search';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import toMaterialStyle from 'material-color-hash';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContent from '@material-ui/core/DialogContent';
import { getVisitors } from '../../redux/visitor/visitorActions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: '100%',
    height: 230,
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    margin: '1rem',
  },
}));

const VisitorSelectList = ({
  visitorPickerOpen,
  handleVisitorPickerClose,
  handleVisitorIds,
  visitorIds,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  // const handleSearch = () => {};

  const visitors = useSelector((state) => state.visitor.visitors);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getVisitors(filter));

    for (const visitorId of Object.keys(visitorsSelected)) {
      visitorsSelected[visitorId] = allSelected;
    }
    setVisitorsSelected(visitorsSelected);

    // eslint-disable-next-line
  }, []);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 20000000000000,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const handleFilterSelect = (name) => {
    setAnchorEl(null);
    // setFilter(name);
  };
  const handleSaveVisitors = () => {
    const visitorIds = [];
    const entriesVisitors = Object.entries(visitorsSelected);

    for (const [visitorId, selected] of entriesVisitors) {
      if (selected) {
        visitorIds.push(visitorId);
      }
    }

    handleVisitorIds(visitorIds);
    handleVisitorPickerClose();
  };

  const [visitorsSelected, setVisitorsSelected] = useState({});

  useEffect(() => {
    if (visitors) {
      const selected = [];
      for (const visitor of visitors.data) {
        if (visitorsSelected[visitor.id]) {
          selected[visitor.id] = true;
        } else {
          selected[visitor.id] = false;
        }

        if (visitorIds && visitorIds.includes(visitor.id)) {
          selected[visitor.id] = true;
        }
      }
      setVisitorsSelected(selected);
    }

    // eslint-disable-next-line
  }, [visitors]);

  const handleChange = (e) => {
    setVisitorsSelected({
      ...visitorsSelected,
      [e.target.value]: e.target.checked,
    });
  };

  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(
      Object.values(visitorsSelected).filter((item) => item === true).length
    );

    // eslint-disable-next-line
  }, [visitorsSelected]);

  const [allSelected, setAllSelected] = useState(null);

  useEffect(() => {
    if (allSelected != null) {
      const selected = {};
      for (const visitorId of Object.keys(visitorsSelected)) {
        selected[visitorId] = allSelected;
      }
      setVisitorsSelected(selected);
    }

    // eslint-disable-next-line
  }, [allSelected]);

  // Filter
  useEffect(() => {
    dispatch(getVisitors(filter));
    // eslint-disable-next-line
  }, [filter]);

  return (
    <Dialog
      onClose={() => {
        handleVisitorPickerClose();
      }}
      aria-labelledby="customized-dialog-title"
      open={visitorPickerOpen}
      // open
      maxWidth="md"
      fullWidth
    >
      <Typography className={classes.dialogTitle} variant="h6">
        Select Visitors {count > 0 ? `(` + count + `)` : ''}
      </Typography>
      <FormControl variant="outlined" style={{ margin: '1rem' }}>
        <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
        <OutlinedInput
          id="outlined-adornment-search"
          type="text"
          name="search"
          value={filter.search}
          onChange={(e) => {
            setFilter({ ...filter, search: e.target.value });
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="search" edge="end">
                <Search />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>
      <DialogContent dividers>
        <div className={classes.root}>
          <Grid
            container
            spacing={2}
            alignItems="stretch"
            justify="space-between"
          >
            {/* <Grid item xs={12} sm={10}>
          <TextField
            id="standard search"
            label="Search"
            name="search"
            fullWidth
            disabled
            variant="outlined"
            onClick={handleSearch}
          />
        </Grid> */}
            <Grid item xs={12} sm={2}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="outlined"
                onClick={handleFilterClick}
              >
                Filter
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleFilterClose}
              >
                <MenuItem name="all" onClick={() => handleFilterSelect('all')}>
                  All
                </MenuItem>
              </Menu>
            </Grid>

            <Grid item xs={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={allSelected != null ? allSelected : false}
                  onChange={(e) => {
                    setAllSelected(e.target.checked);
                  }}
                  value={allSelected != null ? allSelected : false}
                />

                <Typography variant="body1">All</Typography>
              </div>

              <List component="nav">
                {visitors && visitors.data.length !== 0 ? (
                  visitors.data.map((visitor, idx) => {
                    return (
                      <div key={visitor.id} style={{ display: 'flex' }}>
                        <Checkbox
                          checked={visitorsSelected[visitor.id] !== false}
                          onChange={handleChange}
                          value={visitor.id}
                        />
                        <ListItem>
                          <ListItemIcon>
                            <Avatar
                              variant="rounded"
                              style={{
                                backgroundColor: toMaterialStyle(
                                  `${visitor.firstName} ${visitor.lastName}`,
                                  '250'
                                ).backgroundColor,
                              }}
                            >
                              {visitor.firstName.toString().charAt(0)}
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText
                            primary={`${visitor.firstName} ${visitor.lastName}`}
                          />
                        </ListItem>
                      </div>
                    );
                  })
                ) : (
                  <Alert severity="info">No Visitors Available</Alert>
                )}
              </List>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            handleSaveVisitors();
          }}
          color="primary"
        >
          Add Visitors
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VisitorSelectList;
