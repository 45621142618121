import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as firebaseConfig from '../config/firebase';
import firebase from 'firebase/app';
import Paper from '@material-ui/core/Paper';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as firebaseui from 'firebaseui';
import { FcGoogle } from 'react-icons/fc';
import { setVisitor } from '../redux/visitor/visitorActions';
import { AUTH_TYPE } from '../redux/visitor/visitorTypes';
import setAuthToken from '../utils/setAuthToken';
import { MESSAGE_MODE } from '../redux/message-template/messageTemplateTypes';

function Copyright() {
  return (
    <Typography
      style={{ marginTop: '1rem' }}
      variant="body2"
      color="textSecondary"
      align="center"
    >
      {'Copyright © '}
      <Link color="inherit" href="https://identigate.co.ke/">
        Identigate Integrated Solutions Limited
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const SignIn = ({ history, location, match }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const theme = useTheme();

  const loading = useSelector((state) => state.notification.loading);
  const organization = useSelector(
    (state) => state.visitor.authCurrentOrganization,
  );
  const invite = useSelector((state) => state.invite.current);

  useEffect(() => {
    if (match && match.params.id) {
      // dispatch(getOrganizationByPassId(match.params.id));
    } else {
      // dispatch(getOrganizationByPassId());
    }
    // eslint-disable-next-line
  }, []);

  const [showEmail, setShowEmail] = useState(true);
  const [showPhone, setShowPhone] = useState(true);

  useEffect(() => {
    if (invite) {
      setShowEmail(invite.mode === MESSAGE_MODE.SMS ? false : true);
      setShowPhone(invite.mode === MESSAGE_MODE.EMAIL ? false : true);
    }
  }, [invite]);

  useEffect(() => {
    let ui;
    if (firebaseui.auth.AuthUI.getInstance()) {
      ui = firebaseui.auth.AuthUI.getInstance();
    } else {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: async (authResult, redirectUrl) => {
          const { phoneNumber } = authResult.user;
          // console.log(authResult.user)
          const token = await firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true);

          let visitor = {};
          visitor.mobileNumber = phoneNumber.startsWith('+254')
            ? +'0' + phoneNumber.substr(4)
            : phoneNumber;
          visitor.authType = AUTH_TYPE.PHONE;
          // user.idToken = token;
          setAuthToken(token);

          dispatch(setVisitor(visitor));

          return true;
        },
      },
      // signInSuccessUrl: false, //This URL is used to return to that page when we got success response for phone authentication.
      signInOptions: [
        {
          buttonColor: theme.palette.primary.main,
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          defaultCountry: 'KE',
        },
      ],
      tosUrl: `https://soja-pass.web.app/terms-conditions.html`,
      privacyPolicyUrl: `https://soja-pass.web.app/privacy-policy.html`,
    };
    ui.start('#firebaseui-auth-container', uiConfig);

    // eslint-disable-next-line
  }, []);

  const signInGmail = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

    try {
      const result = await firebaseConfig.default
        .auth()
        .signInWithPopup(provider);
      const token = await firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true);
      // console.log(result.user);
      const { displayName, photoURL, email } = result.user;
      let visitor = {};
      visitor.firstName = displayName.split(' ')[0];
      visitor.lastName = displayName.split(' ')[1];
      visitor.image = photoURL;
      visitor.email = email;
      visitor.authType = AUTH_TYPE.GMAIL;

      // visitor.idToken = token;
      setAuthToken(token);
      dispatch(setVisitor(visitor));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />

      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div style={{ padding: '1rem' }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4">
                  {organization ? organization.name : 'Soja Pass'}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Avatar className={classes.avatar}>
            <AccountCircleIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {showEmail && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: '#FFF',
                color: '#000',
                marginBottom: '1.5rem',
                marginTop: '1.5rem',
                display:'none'
              }}
              onClick={signInGmail}
              startIcon={<FcGoogle />}
              disabled={loading}
            >
              <span>Continue With Gmail</span>
            </Button>
          )}
          {/* {showEmail && showPhone && (
            <Typography component="h1" variant="h5">
              <strong>or</strong>
            </Typography>
          )} */}
          {/* 
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => setShowPhoneSection(true)}
            size="large"
            style={{
              background: '#50A3EB',
              color: '#FFF',
              marginBottom: '1.5rem',
              marginTop: '1.5rem',
            }}
            startIcon={<FcPhoneAndroid />}
            disabled={loading}
          >
            <span>Continue With Phone</span>
          </Button> */}
          {showPhone && (
            <div style={{ width: '100%' }} id="firebaseui-auth-container"></div>
          )}
          <Copyright />
        </div>
      </Grid>
    </Grid>
  );
};

export default SignIn;
