import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles, lighten } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MUIDataTable from 'mui-datatables';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Link } from 'react-router-dom';
import CustomSearchRender from '../../utils/CustomSearchRender';
import { getOrganizations } from '../../redux/organization/organizationActions';
import { parseISO } from 'date-fns';
import { format } from 'date-fns/esm';
import AddOrganizationDialog from './AddOrganizationDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  labelRow: {
    backgroundColor: `${lighten(theme.palette.secondary.main, 0.6)}`,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(1),
    borderRadius: '0.5em',
    textDecoration: 'none',
  },
}));

const OrganizationList = ({ history }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);

  const dispatch = useDispatch();

  const organizations = useSelector(
    (state) => state.organization.organizations,
  );
  // const notification = useSelector((state) => state.notification.notification);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 25,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return { name, direction };
  };
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        sortOrder: getSortOrder('id'),
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        sortOrder: getSortOrder('name'),
        display: true,
      },
    },
    {
      name: 'image',
      label: 'Image',
      options: {
        display: false,
      },
    },
    {
      name: 'passId',
      label: 'Pass ID',
      options: {
        display: false,
      },
    },
    {
      name: 'primaryColor',
      label: 'Primary Color',
      options: {
        display: false,
      },
    },
    {
      name: 'secondaryColor',
      label: 'Secondary Color',
      options: {
        display: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        display: true,
        filter: true,
        sortOrder: getSortOrder('createdAt'),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1" color="primary">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy',
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'updatedAt',
      label: 'updatedAt',
      options: {
        display: false,
      },
    },
    {
      name: 'view',
      label: 'Actions',
      options: {
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              component={Link}
              to={`organizations/view/${tableMeta.rowData[0]}`}
              label={value}
              variant="outlined"
              type="submit"
              color="secondary"
            >
              View
            </Button>
          );
        },
      },
    },
    {
      name: 'updatedAt',
      label: 'updatedAt',
      options: {
        display: false,
      },
    },
    {
      name: 'image',
      label: 'image',
      options: {
        display: false,
      },
    },
    {
      name: 'enabled',
      label: 'enabled',
      options: {
        display: false,
      },
    },
    {
      name: 'mobileNo',
      label: 'mobileNo',
      options: {
        display: false,
      },
    },
    {
      name: 'address',
      label: 'address',
      options: {
        display: false,
      },
    },
    {
      name: 'primaryColor',
      label: 'primaryColor',
      options: {
        display: false,
      },
    },
    {
      name: 'secondaryColor',
      label: 'secondaryColor',
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    serverSide: true,
    downloadOptions: {
      filename: 'organizations.csv',
      useDisplayedRowsOnly: false,
    },
    selectableRows: 'none',
    rowHover: false,
    onTableChange: (action, tableState) => {},
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage });
    },
    rowsPerPage: 1,
    rowsPerPageOptions: [1, 10, 20, 30, 50, 100],
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction,
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log('Here');
    dispatch(getOrganizations(filter));

    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (organizations) {
      var finalData =
        organizations &&
        organizations.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [organizations]);

  // Add Org Dialog
  const [addOrgOpen, setAddOrgOpen] = useState(false);
  const handleAddOrg = () => {
    setAddOrgOpen(true);
  };

  return (
    <div>
      <Paper className={classes.root}>
        <div>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            startIcon={<AddBoxIcon />}
            onClick={() => handleAddOrg()}
          >
            Add Organization
          </Button>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {data && (
              <MUIDataTable
                title={'Organization List'}
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </Grid>
        </Grid>

        <AddOrganizationDialog
          addOrgOpen={addOrgOpen}
          handleAddOrgClose={() => setAddOrgOpen(false)}
        />
      </Paper>
    </div>
  );
};

export default OrganizationList;
