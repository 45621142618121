import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: theme.zIndex.drawer + 200,
  },
}));

const AlertButton = ({ title, text, buttonText, type, onClick }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (!onClick) {
      const timer = setTimeout(() => {
        dispatch({ type });
      }, 6000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, []);

  //

  return (
    <div className={classes.root}>
      <Alert
        variant="filled"
        severity="success"
        action={
          <Button onClick={onClick} color="inherit" size="small">
            {buttonText}
          </Button>
        }
      >
        {/*  */}
        <AlertTitle>{title}</AlertTitle>
        {text}
      </Alert>
    </div>
  );
};

export default AlertButton;
