import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  MESSAGE_ERROR,
  GET_MESSAGES,
  GET_MESSAGE,
  REMOVE_MESSAGE,
} from './messageTypes';

const url = process.env.REACT_APP_API_URL + 'messages';

let notification;

// Create Message

export const addMessage = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    notification = {
      message: `Message Sent successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_ERROR,
      payload: 'Failed to Send Message',
    });
  }
};

// Update Message

export const updateMessage = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Updated Message successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_ERROR,
      payload: 'Failed to Update Message',
    });
  }
};

// Get Messages
export const getMessages = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const messages = res.data;
  dispatch({ type: GET_MESSAGES, payload: messages });
};

// Get Message  By ID
export const getMessageByID = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const messageGroup = res.data;
    dispatch({ type: GET_MESSAGE, payload: messageGroup });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_ERROR,
      payload: 'Failed to Get Message',
    });
  }
};

// Delete Message
export const deleteMessage = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    const notification = {
      message: `Deleted Message`,
      type: 'info',
    };

    dispatch({ type: REMOVE_MESSAGE, payload: id });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_ERROR,
      payload: 'Failed to Delete Message',
    });
  }
};
