import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Link } from 'react-router-dom';
import CustomSearchRender from '../../utils/CustomSearchRender';
import { format, parseISO } from 'date-fns/esm';
import { getInvites } from '../../redux/invite/inviteActions';
import AddInviteDialog from './AddInviteDialog';
import { ROLE_SUPER_ADMIN } from '../../redux/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const InviteList = ({ history }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();

  const invites = useSelector((state) => state.invite.invites);
  const currentUser = useSelector((state) => state.auth.current);
  const loading = useSelector((state) => state.notification.loading);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 25,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        console.log(filter.sortOrder);
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };
  const columns = [
    {
      name: 'organizationId',
      label: 'Organization ID',
      options: {
        display: false,
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'organizationName',
      label: 'Organization',
      options: {
        display: currentUser && currentUser.roleName === ROLE_SUPER_ADMIN,
        sortOrder: getSortOrder('organizationName'),
        filter: false,
        viewColumns: false,
        print: false,
        download: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={value}
              // component={Link}
              style={{ textDecoration: 'none', cursor: 'pointer' }}
            />
          );
        },
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        download: false,
        print: false,
        filter: false,
      },
    },
    {
      name: 'mode',
      label: 'Mode',
      options: {
        sortOrder: getSortOrder('mode'),
        display: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'contact',
      label: 'Contact',
      options: {
        display: true,
        sortOrder: getSortOrder('description'),
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'value',
      label: 'Value',
      options: {
        display: false,
        sort: false,
        // filterType: 'textField',
        filter: false,
      },
    },

    {
      name: 'createdAt',
      label: 'Created On',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'pp dd-MM-yyyy',
              )}
            </Typography>
          );
        },
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('createdAt'),
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated On',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'pp dd-MM-yyyy',
              )}
            </Typography>
          );
        },
        display: false,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('updatedAt'),
      },
    },
    {
      name: 'view',
      label: 'Actions',
      options: {
        display: false,
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              component={Link}
              to={`/admin/main/invites/view/${tableMeta.rowData[2]}`}
              label={value}
              variant="outlined"
              type="submit"
              color="secondary"
            >
              View
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    downloadOptions: {
      filename: 'invites.csv',
      useDisplayedRowsOnly: false,
    },
    serverSide: true,
    rowHover: false,
    selectableRows: 'none',
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    rowsPerPage: 5,
    count: invites && invites.count,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      //
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction,
      });
    },
    // customFilterDialogFooter: filterList => {
    //   return (
    //     <div style={{ marginTop: '40px' }}>
    //       <Button variant="contained" onClick={handleFilterSubmit(filterList)}>Apply Filters*</Button>
    //     </div>
    //   );
    // }
  };

  // const handleFilterSubmit = (filterList) => {
  //   console.log(filterList);
  // }

  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log('Here');
    dispatch(getInvites(filter));

    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (invites) {
      var finalData =
        invites &&
        invites.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [invites]);

  // Add Org Dialog
  const [addInviteOpen, setAddInviteOpen] = useState(false);
  const handleAddInviteOpen = () => {
    setAddInviteOpen(true);
  };

  return (
    <div>
      <Paper className={classes.root}>
        <div>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={() => handleAddInviteOpen()}
            startIcon={<AddBoxIcon />}
          >
            Add
          </Button>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {loading && (
              <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                <CircularProgress />
              </div>
            )}
            {data && (
              <MUIDataTable
                title={'Invites'}
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
      <AddInviteDialog
        addInviteOpen={addInviteOpen}
        handleAddInviteClose={() => setAddInviteOpen(false)}
      />
    </div>
  );
};

export default InviteList;
