import React, { useState } from 'react';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flex: '1 0 auto'
  },
  searchText: {
    flex: '0.8 0'
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main
    }
  }
}));

const CustomSearchRender = ({ options, onHide, onSearch }) => {
  const classes = useStyles();
  const [text, setText] = useState('');

  const handleTextChange = event => {
    setText(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <Grow appear in={true} timeout={300}>
      <div className={classes.main}>
        <TextField
          placeholder={'Search'}
          variant="outlined"
          className={classes.searchText}
          InputProps={{
            'aria-label': options.textLabels.toolbar.search
          }}
          value={text}
          onChange={handleTextChange}
          fullWidth={true}
        />
        <IconButton className={classes.clearIcon} onClick={onHide}>
          <ClearIcon />
        </IconButton>
      </div>
    </Grow>
  );
};

export default CustomSearchRender;
