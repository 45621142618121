import {
  AUTH_ERROR,
  SET_USER,
  CLEAR_USER,
  FORGOT_PASS,
  GET_CURRENT_USER,
  SET_CURRENT_USER,
  UPDATE_CURRENT_ORGANIZATION,
  GET_CURRENT_ORGANIZATION,
} from './authTypes';
import setAuthToken from '../../utils/setAuthToken';

const initialState = {
  loading: false,
  error: null,
  current: null,
  organization: null,
};

export default (state = { initialState }, action) => {
  switch (action.type) {
    case AUTH_ERROR:
      localStorage.removeItem('token');
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case SET_USER:
      const user = action.payload;
      localStorage.setItem('token', user.accessToken);
      setAuthToken(user.accessToken);
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        current: {
          ...state.current,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          userName: action.payload.userName,
          roleId: action.payload.roleId,
        },
        loading: false,
      };
    case UPDATE_CURRENT_ORGANIZATION:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case GET_CURRENT_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
        loading: false,
      };
    case CLEAR_USER:
      localStorage.removeItem('token');
      return {
        ...state,
        current: null,
      };
    case FORGOT_PASS:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
};
