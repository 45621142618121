import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import LoginAdmin from '../pages/auth/admin/LoginAdmin';
import { setCurrentPage } from '../redux/pages/pagesActions';
import { getCurrentUser } from '../redux/auth/authActions';
import UserList from '../pages/users/UserList';
import AddUser from '../pages/users/AddUser';
import EditUser from '../pages/users/EditUser';
import UserView from '../pages/users/view/UserView';
import MessageTemplateList from '../pages/message-template/MessageTemplateList';
import MessageTemplateView from '../pages/message-template/view/MessageTemplateView';
import AddMessageTemplate from '../pages/message-template/AddMessageTemplate';
import MessageGroupList from '../pages/message-group/MessageGroupList';
import AddMessageGroup from '../pages/message-group/AddMessageGroup';
import MessageGroupView from '../pages/message-group/view/MessageGroupView';
import EditMessageTemplate from '../pages/message-template/EditMessageTemplate';
import ActivateUser from '../pages/auth/admin/ActivateUser';
import ForgotPassword from '../pages/auth/admin/ForgotPassword';
import ResetPassword from '../pages/auth/admin/ResetPassword';
import SendMessage from '../pages/messaging/create/SendMessage';
import MessageList from '../pages/messaging/MessageList';
import EditMessageGroup from '../pages/message-group/EditMessageGroup';
import AdminNavbar from '../components/admin/AdminNavbar';
import AddVisitor from '../pages/visitors/AddVisitor';
import VisitorView from '../pages/visitors/view/VisitorView';
import VisitorList from '../pages/visitors/VisitorList';
import OrganizationList from '../pages/organization/OrganizationList';
import EditOrganization from '../pages/organization/EditOrganization';
import OrganizationView from '../pages/organization/view/OrganizationView';
import SettingsHome from '../pages/settings/SettingsHome';
import InviteList from '../pages/invites/InviteList';
import Dashboard from '../pages/Dashboard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    marginTop: '4rem',
  },
  notification: {
    position: 'fixed',
    top: 0,
    left: 0,
  },
}));

const Admin = ({ location, history }) => {
  const classes = useStyles();
  const page = useSelector((state) => state.page.page);
  const current = useSelector((state) => state.auth.current);
  const organization = useSelector((state) => state.auth.organization);
  const dispatch = useDispatch();

  useEffect(() => {
    // Listen for Route Changes

    const { pathname } = location;
    // console.log('New path:', pathname.split('/').pop());
    // console.log('New path:', location);
    dispatch(setCurrentPage(pathname));
    // Listen for Auth
    if (localStorage.getItem('token') == null) {
      // console.log('No token');
      if (location.pathname.includes('forgot-password')) {
        return;
      }
      if (location.pathname.includes('reset-password')) {
        return;
      }
      if (location.pathname.includes('activate')) {
        // history.push(location.pathname);
        return;
      }
      if (!pathname.includes('/admin/login')) {
        history.push('/admin/login');
      }
    } else {
      if (!current) {
        dispatch(getCurrentUser());
      }
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes('reset-password')) {
      return;
    } else if (location.pathname.includes('forgot-password')) {
      return;
    } else if (location.pathname.includes('/activate')) {
      return;
    } else if (!current && localStorage.getItem('token') == null) {
      history.push('/admin/login');
    }
    // eslint-disable-next-line
  }, [current]);
  // console.log(organization);
  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${organization ? organization.name : 'Soja Pass'} ${
          page ? ' | ' + page : ''
        }`}</title>
      </Helmet>

      <Route exact path="/admin/login" component={LoginAdmin} />
      <Route exact path="/admin/activate/:tk" component={ActivateUser} />
      <Route exact path="/admin/forgot-password" component={ForgotPassword} />
      <Route path="/admin/reset-password" component={ResetPassword} />

      <Route
        path="(^/admin/main)"
        render={(props) => (
          <Fragment>
            <AdminNavbar {...props} />
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.content}>
                  <Switch>
                    <Route exact path="/admin/main" component={Dashboard} />
                    <Route
                      exact
                      path="/admin/main/users/add"
                      component={AddUser}
                    />
                    <Route
                      exact
                      path="/admin/main/users/edit/:id"
                      component={EditUser}
                    />
                    <Route
                      exact
                      path="/admin/main/users/view/:id"
                      component={UserView}
                    />
                    <Route
                      exact
                      path="/admin/main/users"
                      component={UserList}
                    />

                    <Route
                      exact
                      path="/admin/main/visitors"
                      component={VisitorList}
                    />
                    <Route
                      exact
                      path="/admin/main/visitors/view/:id"
                      component={VisitorView}
                    />
                    <Route
                      exact
                      path="/admin/main/visitors/add"
                      component={AddVisitor}
                    />

                    <Route
                      exact
                      path="/admin/main/message-template/view/:id"
                      component={MessageTemplateView}
                    />

                    <Route
                      exact
                      path="/admin/main/message-template/add"
                      component={AddMessageTemplate}
                    />

                    <Route
                      exact
                      path="/admin/main/message-template/edit/:id"
                      component={EditMessageTemplate}
                    />
                    <Route
                      exact
                      path="/admin/main/message-template"
                      component={MessageTemplateList}
                    />

                    <Route
                      exact
                      path="/admin/main/message-group/view/:id"
                      component={MessageGroupView}
                    />

                    <Route
                      exact
                      path="/admin/main/message-group/add"
                      component={AddMessageGroup}
                    />

                    <Route
                      exact
                      path="/admin/main/message-group/edit/:id"
                      component={EditMessageGroup}
                    />
                    <Route
                      exact
                      path="/admin/main/messaging/send"
                      component={SendMessage}
                    />
                    <Route
                      exact
                      path="/admin/main/messaging"
                      component={MessageList}
                    />
                    <Route
                      exact
                      path="/admin/main/message-group"
                      component={MessageGroupList}
                    />

                    <Route
                      exact
                      path="/admin/main/organizations/edit/:id"
                      component={EditOrganization}
                    />
                    <Route
                      exact
                      path="/admin/main/organizations/view/:id"
                      component={OrganizationView}
                    />
                    <Route
                      exact
                      path="/admin/main/organizations"
                      component={OrganizationList}
                    />
                    <Route
                      path="/admin/main/settings"
                      component={SettingsHome}
                    />

                    <Route path="/admin/main/invites" component={InviteList} />
                  </Switch>
                </div>
              </Grid>
            </Grid>
          </Fragment>
        )}
      />
    </div>
  );
};

export default Admin;
