import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const MessageGroupRecipients = ({ visitors, history }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: {
        filter: false,
      },
    },

    {
      name: 'mobileNumber',
      label: 'Mobile Number',
      options: {
        filter: false,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
      },
    },

    {
      name: 'view',
      label: 'Actions',
      options: {
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              component={Link}
              to={`/main/admin/visitors/view/${tableMeta.rowData[0]}`}
              variant="outlined"
              type="submit"
              color="secondary"
            >
              View
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    serverSide: false,
    filter: false,
    responsive: 'vertical',
    rowHover: false,
    selectableRows: 'none',
    elevation: 0,
    count: visitors && visitors.count,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
  };

  const [data, setData] = useState([]);

  // When store/state data is updated
  useEffect(() => {
    if (visitors) {
      var finalData =
        visitors &&
        visitors.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);d
    }
  }, [visitors]);

  return (
    <div className={classes.root}>
      {data && <MUIDataTable data={data} columns={columns} options={options} />}
    </div>
  );
};

export default MessageGroupRecipients;
