import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { TiSortAlphabetically } from 'react-icons/ti';
import SubjectIcon from '@material-ui/icons/Subject';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import Collapse from '@material-ui/core/Collapse';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateMessageTemplate,
  getMessageTemplateById,
  updateMessageTemplateAdmin,
} from '../../redux/message-template/messageTemplateActions';
import { getMessageGroups } from '../../redux/message-group/messageGroupActions';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { MESSAGE_TYPES } from '../../redux/message-template/messageTemplateTypes';
import { ROLE_SUPER_ADMIN } from '../../redux/role/roleTypes';
import { getOrganizations } from '../../redux/organization/organizationActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  unitTypeCard: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const EditMessageTemplate = ({ match, history }) => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);
  const messageGroups = useSelector(
    (state) => state.messageGroup.messageGroups,
  );
  const current = useSelector((state) => state.messageTemplate.current);
  const currentUser = useSelector((state) => state.auth.current);
  const organizations = useSelector(
    (state) => state.organization.organizations,
  );

  const [form, setForm] = useState({
    name: '',
    description: '',
    mode: 'email',
    content: '',
    subject: '',
    messageGroupId: null,
    type: MESSAGE_TYPES.NONE,
    typeDefault: null,
    organizationId: null,
  });

  useEffect(() => {
    dispatch(getMessageGroups());
    dispatch(getMessageTemplateById(match.params.id));

    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Edit Message Template') &&
      notification &&
      notification.type === 'success'
    ) {
      history.push(`/admin/main/message-template/view/${match.params.id}`);
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.content = htmlContent;
    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(updateMessageTemplateAdmin(form.content, match.params.id));
    } else {
      dispatch(updateMessageTemplate(form, match.params.id)(form));
    }
  };
  useEffect(() => {
    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(getOrganizations());
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    generateSuggestions();
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    if (current) {
      setForm(current);
      setUserType(form.content.includes('value="user') ? 'user' : 'visitor');
      // if (form.mode === "email") {

      // TODO: Fix Render of Current HTML Form
      // const content = jsStringEscape(current.content);

      // console.log(jsStringEscape(current.content));

      const contentBlock = htmlToDraft(current.content);
      // console.log(content)

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorStateInitial = EditorState.createWithContent(contentState);

        // console.log(editorStateInitial)
        setEditorState(editorStateInitial);
      }
      // }
    }
    // eslint-disable-next-line
  }, [current]);

  useEffect(() => {
    if (editorState) {
      // Replace annoying href tags
      const html = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      ).replace(/(href=".*?")/g, '');

      setHtmlContent(html);
    }
  }, [editorState]);

  const [suggestions, setSuggestions] = useState([]);

  // Change User Suggestions
  const [userType, setUserType] = useState('member');

  const generateSuggestions = () => {
    let suggestions = [
      { text: 'First Name', value: `${userType}.firstName` },
      { text: 'Last Name', value: `${userType}.lastName` },
      { text: 'Mobile Number', value: `${userType}.mobileNumber` },
      { text: 'Password Reset Link', value: `${userType}.passwordResetLink` },
      { text: 'User Activate Link', value: `${userType}.activateLink` },
    ];
    suggestions = suggestions.concat(getOptionalSuggestions());
    setSuggestions(suggestions);
  };

  const getOptionalSuggestions = () => {
    let optionalSuggestions = [];

    if (form.type === MESSAGE_TYPES.INVITE) {
      optionalSuggestions = optionalSuggestions.concat(
        {
          text: 'Invite Contact',
          value: 'invite.contact',
        },
        {
          text: 'Invite Link',
          value: 'invite.link',
        },
      );
    }
    return optionalSuggestions;
  };

  if (!messageGroups && !current) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid container direction="row" spacing={2}>
              {currentUser && currentUser.roleName === ROLE_SUPER_ADMIN && (
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-organization-simple">
                      Select Organization
                    </InputLabel>
                    <Select
                      // inputRef={register}
                      name="organizationId"
                      inputProps={{
                        name: 'organizationId',
                        id: 'outlined-organization-simple',
                      }}
                      value={form.organizationId}
                      onChange={(e) =>
                        setForm({ ...form, organizationId: e.target.value })
                      }
                    >
                      {organizations &&
                        organizations.data.map((organization) => (
                          <MenuItem
                            key={organization.id}
                            value={organization.id}
                          >
                            <em>{organization.name}</em>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={form.name}
                  name="name"
                  onChange={onChange}
                  id="name"
                  label="Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TiSortAlphabetically size={18} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-mode-simple">
                    Select Mode
                  </InputLabel>
                  <Select
                    fullWidth
                    value={form.mode}
                    onChange={onChange}
                    name="mode"
                    inputProps={{
                      name: 'mode',
                      id: 'outlined-mode-simple',
                    }}
                  >
                    <MenuItem value="sms">
                      <em>SMS</em>
                    </MenuItem>
                    <MenuItem value="email">
                      <em>Email</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-message-type-simple">
                    Select Message Type
                  </InputLabel>

                  <Select
                    fullWidth
                    value={form.type}
                    onChange={onChange}
                    name="type"
                    inputProps={{
                      name: 'type',
                      id: 'outlined-message-type-simple',
                    }}
                  >
                    {Object.entries(MESSAGE_TYPES).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        <em>{value.split('_').join(' ')}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-message-group-simple">
                    Select Message Group
                  </InputLabel>
                  <Select
                    fullWidth
                    value={form.messageGroupId}
                    onChange={onChange}
                    name="messageGroupId"
                    inputProps={{
                      name: 'messageGroupId',
                      id: 'outlined-message-group-simple',
                    }}
                  >
                    {messageGroups &&
                      messageGroups.data.map((messageGroup) => (
                        <MenuItem key={messageGroup.id} value={messageGroup.id}>
                          <em>{messageGroup.name}</em>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={form.subject}
                  name="subject"
                  onChange={onChange}
                  id="subject"
                  label="Subject"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SubjectIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  // editorClassName="demo-editor"
                  onEditorStateChange={(editorState) =>
                    setEditorState(editorState)
                  }
                  placeholder="Enter Message Content"
                  editorStyle={{
                    border: `1px solid ${theme.palette.primary.light}`,
                    padding: '1rem',
                    borderRadius: '5px',
                  }}
                  mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions,
                  }}
                  hashtag={{}}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              style={{ marginTop: '2rem' }}
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                !htmlContent ||
                !form.name ||
                !form.type ||
                !form.mode ||
                !form.subject ||
                loading
              }
            >
              Update Message Template
            </Button>
          </Grid>
        </form>
      </Paper>
    );
  }
};

export default EditMessageTemplate;
