import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';
import {
  updateOrganization,
  getOrganizationByID,
} from '../../redux/organization/organizationActions';
import { useTheme } from '@material-ui/core/styles';
import {BlockPicker} from 'react-color';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  userCard: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  color: {
    width: '100%',
    height: '14px',
    borderRadius: '2px',
  },
  swatchPrimary: {
    padding: '1rem',
    background: theme.palette.primary,
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  swatchSecondary: {
    padding: '1rem',
    background: theme.palette.secondary,
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const EditOrganization = ({ history, match }) => {
  const classes = useStyles();

  const theme = useTheme();

  const dispatch = useDispatch();

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const current = useSelector((state) => state.organization.current);

  useEffect(() => {
    dispatch(getOrganizationByID(match.params.id));
    // eslint-disable-next-line
  }, []);

  const [form, setForm] = useState({
    id: null,
    name: null,
    image: null,
    passId: null,
    active: false,
    primaryColor: theme.palette.primary,
    secondaryColor: theme.palette.secondary,
  });

  useEffect(() => {
    if (current) {
      form.id = current.id;
      form.name = current.name;
      form.active = current.active;
      form.image = current.image;
      form.passId = current.passId;
      form.primaryColor = current.primaryColor;
      form.secondaryColor = current.secondaryColor;

      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(updateOrganization(form,form.id));
  };

  useEffect(() => {
    if (notification && notification.type === 'success') {
      history.push('/admin/main/organizations');
    }
    // eslint-disable-next-line
  }, [notification]);

  if (!current) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={onChange}
                    id="name"
                    label="Name"
                    value={form.name}
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="passId"
                    variant="outlined"
                    fullWidth
                    type="passId"
                    onChange={onChange}
                    id="passId"
                    label="Pass ID"
                    value={form.passId}
                    disabled
                    autoFocus
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={form.active}
                      onChange={(e) =>
                        setForm({ ...form, active: e.target.checked })
                      }
                      value="active"
                      name="active"
                      color="primary"
                    />
                  }
                  value={form.active}
                  label="Enabled"
                />
              </Grid>
           
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                <strong>Primary Color</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                <strong>Secondary Color</strong>
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <BlockPicker
                color={form.primaryColor}
                colors={[
                  '#FF6900',
                  '#FCB900',
                  '#7BDCB5',
                  '#00D084',
                  '#8ED1FC',
                  '#0693E3',
                  '#ABB8C3',
                  '#EB144C',
                  '#F78DA7',
                  '#9900EF',
                ]}
                onChange={(color, event) => {
                  setForm({ ...form, primaryColor: color.hex });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BlockPicker
                color={form.secondaryColor}
                colors={[
                  '#FF6900',
                  '#FCB900',
                  '#7BDCB5',
                  '#00D084',
                  '#8ED1FC',
                  '#0693E3',
                  '#ABB8C3',
                  '#EB144C',
                  '#F78DA7',
                  '#9900EF',
                ]}
                onChange={(color, event) => {
                  setForm({ ...form, secondaryColor: color.hex });
                }}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            style={{ marginTop: '2rem' }}
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!form.name || loading}
          >
            Update Organization
          </Button>
          <Grid item xs={12}>
            {loading && <LinearProgress variant="query" color="secondary" />}
          </Grid>
        </form>
      </Paper>
    );
  }
};

export default EditOrganization;
