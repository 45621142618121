import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import SendIcon from '@material-ui/icons/Send';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../../redux/message/messageActions';
import { getUsers } from '../../../redux/user/userActions';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import '../../../App.css';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { getMessageGroups } from '../../../redux/message-group/messageGroupActions';
import { getVisitors } from '../../../redux/visitor/visitorActions';
import { ROLE_SUPER_ADMIN } from '../../../redux/role/roleTypes';
import { getOrganizations } from '../../../redux/organization/organizationActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const SendMessage = ({ history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const SEND_OPTION = {
    VISITOR: 'Send to Visitor',
    GROUP: 'Send to Group',
  };

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);
  const visitors = useSelector((state) => state.visitor.visitors);
  const messageGroups = useSelector(
    (state) => state.messageGroup.messageGroups,
  );
  const currentUser = useSelector((state) => state.auth.current);
  const organizations = useSelector(
    (state) => state.organization.organizations,
  );

  const [form, setForm] = useState({
    mode: 'sms',
    content: '',
    name: '',
    subject: '',
    messageTemplateId: null,
    visitorId: null,
    userId: null,
    messageGroupId: null,
    organizationId: null,
  });

  // Init Leases
  useEffect(() => {
    dispatch(getVisitors());
    dispatch(getUsers());
    dispatch(getMessageGroups());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      page &&
      page.includes('Send Message') &&
      notification &&
      notification.type === 'success'
    ) {
      history.push('/admin/main/messaging');
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.content = htmlContent;
    dispatch(addMessage(form));
  };

  useEffect(() => {
    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(getOrganizations());
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const theme = useTheme();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlContent, setHtmlContent] = useState('');

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const [sendOption, setSendOption] = useState(SEND_OPTION.VISITOR);

  useEffect(() => {
    if (form.visitorId || form.messageGroupId) {
      generateSuggestions('visitor');
    } else if (form.userId) {
      generateSuggestions('user');
    }
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    if (editorState) {
      setHtmlContent(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      );
    }
  }, [editorState]);

  const [suggestions, setSuggestions] = useState([]);

  const generateSuggestions = (userType) => {
    setSuggestions([
      {
        text: 'First Name',
        value: `${userType}.firstName`,
        url: `${userType}.firstName`,
      },
      {
        text: 'Last Name',
        value: `${userType}.lastName`,
        url: `${userType}.lastName`,
      },
      {
        text: 'Mobile Number',
        value: `${userType}.mobileNumber`,
        url: `${userType}.mobileNumber`,
      },
    ]);
  };

  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Grid container spacing={2}>
          {currentUser && currentUser.roleName === ROLE_SUPER_ADMIN && (
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-organization-simple">
                  Select Organization
                </InputLabel>
                <Select
                  // inputRef={register}
                  name="organizationId"
                  inputProps={{
                    name: 'organizationId',
                    id: 'outlined-organization-simple',
                  }}
                  value={form.organizationId}
                  onChange={(e) => {
                    onChange(e);
                    dispatch(getVisitors({ organizationId: e.target.value }));
                    dispatch(getUsers({ organizationId: e.target.value }));
                    dispatch(
                      getMessageGroups({ organizationId: e.target.value }),
                    );
                  }}
                >
                  {organizations &&
                    organizations.data.map((organization) => (
                      <MenuItem key={organization.id} value={organization.id}>
                        <em>{organization.name}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-option-simple">
                Select Send Option
              </InputLabel>
              <Select
                fullWidth
                value={sendOption}
                onChange={(e) => {
                  generateSuggestions(
                    e.target.value.toLowerCase().includes('visitor') ||
                      e.target.value.toLowerCase().includes('group')
                      ? 'visitor'
                      : 'user',
                  );
                  setSendOption(e.target.value);
                }}
                name="sendOption"
                inputProps={{
                  name: 'sendOption',
                  id: 'outlined-option-simple',
                }}
              >
                {Object.entries(SEND_OPTION).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    <em>{value}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {sendOption === SEND_OPTION.VISITOR && (
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-visitor-simple">
                  Select Visitor
                </InputLabel>
                <Select
                  fullWidth
                  value={form.visitorId}
                  onChange={onChange}
                  name="visitorId"
                  inputProps={{
                    name: 'visitorId',
                    id: 'outlined-visitor-simple',
                  }}
                >
                  {visitors &&
                    visitors.data.map((visitor) => (
                      <MenuItem key={visitor.id} value={visitor.id}>
                        <em>
                          {visitor.firstName} {visitor.lastName}
                        </em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {sendOption === SEND_OPTION.GROUP && (
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-message-groups-simple">
                  Select Message Groups
                </InputLabel>
                <Select
                  fullWidth
                  value={form.messageGroupId}
                  onChange={onChange}
                  name="messageGroupId"
                  inputProps={{
                    name: 'messageGroupId',
                    id: 'outlined-message-groups-simple',
                  }}
                >
                  {messageGroups &&
                    messageGroups.data.map((messageGroup) => (
                      <MenuItem key={messageGroup.id} value={messageGroup.id}>
                        <em>{messageGroup.name}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={form.name}
              name="name"
              onChange={onChange}
              id="name"
              label="Name"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={form.subject}
              name="subject"
              onChange={onChange}
              id="subject"
              label="Subject"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-mode-simple">
                Select Mode
              </InputLabel>
              <Select
                fullWidth
                value={form.mode}
                onChange={onChange}
                name="mode"
                inputProps={{
                  name: 'mode',
                  id: 'outlined-mode-simple',
                }}
              >
                <MenuItem value="sms">
                  <em>SMS</em>
                </MenuItem>
                <MenuItem value="email">
                  <em>Email</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              onEditorStateChange={onEditorStateChange}
              editorStyle={{
                border: `1px solid ${theme.palette.primary.light}`,
                padding: '1rem',
                borderRadius: '5px',
              }}
              mention={{
                separator: ' ',
                trigger: '@',
                suggestions,
              }}
              hashtag={{}}
            />
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={
              form.subject.length === 0 || form.mode.length === 0 || loading
            }
            endIcon={<SendIcon size={32} />}
          >
            Send Message
          </Button>
          <Grid item xs={12}>
            {loading && <LinearProgress variant="query" color="secondary" />}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default SendMessage;
