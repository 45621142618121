import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { SET_VISITOR_ORGANIZATION } from '../../redux/visitor/visitorTypes';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectOrganizaton = ({ selectOrgOpen, handleSelectOrgClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.visitor.authOrganizations);
  const currentOrganization = useSelector(
    (state) => state.visitor.authCurrentOrganization,
  );

  const setOrganization = (currentOrganization) => {
    dispatch({ type: SET_VISITOR_ORGANIZATION, payload: currentOrganization });
    handleSelectOrgClose();
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={selectOrgOpen}
        onClose={handleSelectOrgClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleSelectOrgClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Organizations
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {organizations &&
            organizations.data.map((organization) => {
              return (
                <div key={organization.id}>
                  <ListItem
                    button
                    style={{ padding: '1rem' }}
                    selected={organization === currentOrganization}
                    onClick={() => setOrganization(organization)}
                  >
                    <ListItemText primary={organization.name} />
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
        </List>
      </Dialog>
    </div>
  );
};

export default SelectOrganizaton;
