import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateUser,
  updateUserAdmin,
} from '../../../redux/user/userActions';
import { ROLE_SUPER_ADMIN } from '../../../redux/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const ProfileSettingsView = ({ user, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification.notification);
  const currentUser = useSelector((state) => state.auth.current);
  const page = useSelector((state) => state.page.page);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const [form, setForm] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    mobileNumber: user.mobileNumber,
    roleId: user.roleId,
    organizationId: user.organizationId,
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Profile Users') &&
      notification &&
      notification.type === 'success'
    ) {
      history.push('/main/admin/users');
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(updateUserAdmin(user.id,form));
    } else {
      dispatch(updateUser(user.id, form));
    }
  };
  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              value={form.firstName}
              onChange={onChange}
              id="firstName"
              label="First Name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              onChange={onChange}
              id="lastName"
              label="Last Name"
              value={form.lastName}
              name="lastName"
              autoComplete="lname"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              value={form.email}
              label="Email Address"
              name="email"
              onChange={onChange}
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              type="number"
              id="mobileNumber"
              value={form.mobileNumber}
              label="Mobile Number"
              name="mobileNumber"
              onChange={onChange}
              autoComplete="phoneNumber"
            />
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={
              !form.email ||
              !form.firstName ||
              !form.lastName ||
              !form.mobileNumber ||
              form.roleId === 'None'
            }
          >
            Save
          </Button>
        </Grid>
      </form>
    </Paper>
  );
};

export default ProfileSettingsView;
