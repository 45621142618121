import { GET_SETTING } from './settingTypes';

const initialState = {
  current: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTING:
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
};
